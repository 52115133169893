/* eslint import/no-extraneous-dependencies: off*/
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import userService from 'app/services/userService';
import axios from 'axios';
import Query from 'app/main/query/user';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const setUserData = user => async (dispatch, getState) => {
	const payload = {
		query: Query.getTeamInvites
	};
	dispatch(getTeamInvites(payload));
	localStorage.setItem('userData', JSON.stringify(user));
	/*
    Set User Settings
     */
	if (user && user.data) {
		dispatch(setDefaultSettings(user.data.settings));
	}

	dispatch(setUser(user));
};

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(user));

	return dispatch(setUserData(newUser));
};

export const getSetUserSetting = createAsyncThunk(
	'auth/user/getSetUserSetting',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const enableDisableNotification = createAsyncThunk(
	'auth/user/enableDisableNotification',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		instance.defaults.headers.common.matrixauth = `Bearer ${userService.getMatrixToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const enableDisableShowPriceChangeInPercentage = createAsyncThunk(
	'auth/user/enableDisableShowPriceChangeInPercentage',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getblockUsersList = createAsyncThunk(
	'auth/user/getblockUsersList',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const unBlockUsersById = createAsyncThunk('auth/user/unBlockUsersById', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const blockUserId = createAsyncThunk('auth/user/blockUserId', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const getAccountTypeAndUpdateType = createAsyncThunk(
	'auth/user/getAccountTypeAndUpdateType',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const accountDeactivateAndDeleteById = createAsyncThunk(
	'auth/user/accountDeactivateAndDeleteById',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getAboutUsAndContactUsDetails = createAsyncThunk(
	'auth/user/getAboutUsAndContactUsDetails',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getHelpForAccount = createAsyncThunk(
	'auth/user/getHelpForAccount',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getTeamInvites = createAsyncThunk('auth/user/getTeamInvites', async (data, { getState, dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return errMsg;
	}
});

export const updateInviteRequestStatus = createAsyncThunk(
	'auth/user/updateInviteRequestStatus',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const getUserDetailById = createAsyncThunk(
	'auth/user/getUserDetailById',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const createUniqueApiKey = createAsyncThunk(
	'auth/user/createUniqueApiKey',
	async (data, { getState, dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return errMsg;
		}
	}
);

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	// if (!user.role || user.role.length === 0) {
	// 	// is guest
	// 	return null;
	// }

	history.push({
		pathname: '/login'
	});

	switch (user.from) {
		case 'firebase': {
			break;
		}
		case 'auth0': {
			break;
		}
		default: {
			userService.logout();
		}
	}

	dispatch(setInitialSettings());

	return dispatch(userLoggedOut());
};

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	switch (user.from) {
		case 'firebase': {
			break;
		}
		case 'auth0': {
			break;
		}
		default: {
			userService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved with api' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
	}
};

const initialState = {
	role: [], // guest
	data: {
		name: '',
		photoURL: '',
		email: '',
		shortcuts: ['calendar', 'mail', 'contacts', 'todo']
	}
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState: {
		blockAccountsDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		deleteAccountDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		helpDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		aboutDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		contactDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	},
	reducers: {
		openBlockAccountsDialog: (state, action) => {
			state.blockAccountsDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeBlockAccountsDialog: (state, action) => {
			state.blockAccountsDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openDeleteAccountDialog: (state, action) => {
			state.deleteAccountDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeDeleteAccountDialog: (state, action) => {
			state.deleteAccountDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openHelpDialog: (state, action) => {
			state.helpDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeHelpDialog: (state, action) => {
			state.helpDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openAboutDialog: (state, action) => {
			state.aboutDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeAboutDialog: (state, action) => {
			state.aboutDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openContactDialog: (state, action) => {
			state.contactDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeContactDialog: (state, action) => {
			state.contactDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		setUser: (state, action) => action.payload,
		resetuser: (state, action) => {
			state.getSetUserSettingData = {};
			state.enableDisableNotificationData = {};
			state.enableDisableShowPriceChangeInPercentageData = {};
			state.getblockUsersListData = {};
			state.updateInviteRequestStatusData = {};
			state.getUserDetailByIdData = {};
			state.createUniqueApiKeyData = {};
			state.getAboutUsAndContactUsDetailsData = {};
		},
		resetaboutandcontact: (state, action) => {
			state.getAboutUsAndContactUsDetailsData = {};
		},
		userLoggedOut: (state, action) => initialState
	},
	extraReducers: {
		[getSetUserSetting.fulfilled]: (state, action) => ({
			...state,
			getSetUserSettingData: action.payload
		}),
		[enableDisableNotification.fulfilled]: (state, action) => ({
			...state,
			enableDisableNotificationData: action.payload
		}),
		[enableDisableShowPriceChangeInPercentage.fulfilled]: (state, action) => ({
			...state,
			enableDisableShowPriceChangeInPercentageData: action.payload
		}),
		[getAccountTypeAndUpdateType.fulfilled]: (state, action) => ({
			...state,
			getAccountTypeAndUpdateTypeData: action.payload
		}),
		[getblockUsersList.fulfilled]: (state, action) => ({
			...state,
			getblockUsersListData: action.payload
		}),
		[getAboutUsAndContactUsDetails.fulfilled]: (state, action) => ({
			...state,
			getAboutUsAndContactUsDetailsData: action.payload
		}),
		[getHelpForAccount.fulfilled]: (state, action) => ({
			...state,
			getHelpForAccountData: action.payload
		}),
		[getTeamInvites.fulfilled]: (state, action) => ({
			...state,
			getTeamInvitesData: action.payload
		}),
		[updateInviteRequestStatus.fulfilled]: (state, action) => ({
			...state,
			updateInviteRequestStatusData: action.payload
		}),
		[getUserDetailById.fulfilled]: (state, action) => ({
			...state,
			getUserDetailByIdData: action.payload
		}),
		[createUniqueApiKey.fulfilled]: (state, action) => ({
			...state,
			createUniqueApiKeyData: action.payload
		})
	}
});

export const {
	setUser,
	userLoggedOut,
	openBlockAccountsDialog,
	closeBlockAccountsDialog,
	openDeleteAccountDialog,
	closeDeleteAccountDialog,
	openHelpDialog,
	closeHelpDialog,
	openAboutDialog,
	closeAboutDialog,
	openContactDialog,
	closeContactDialog,
	resetuser,
	resetaboutandcontact
} = userSlice.actions;

export default userSlice.reducer;
