const getAllNewsDetails = `query {
  getAllNewsDetails(pageNumber:1,pageSize:1){
  data{
    
title
description
  }
message
status

  }
}
  `;

const newsUpVoteDownVote = `mutation newsUpVoteDownVote(
    $isDownVote: Boolean!
    $isUpVote: Boolean!
    $newsId: Int!
  ) {
    newsUpVoteDownVote(
      isDownVote: $isDownVote
      isUpVote: $isUpVote
      newsId: $newsId
    ) {
      cts
      message
      status
      uts
    }
  }
  `;

const getNewsComments = `query getNewsComments($newsId: Int!,$pageNumber: Int,$pageSize: Int ) {
    getNewsComments(newsId: $newsId,pageNumber: $pageNumber, pageSize: $pageSize) {
      cts
      data {
        s2_pc_news_comments_s2_newsTos2_pc_news_comments_id_s2_news {
          cts
          response {
            comment
            news_comment_id
            user_fullname
            user_id
            user_image
            username
            total_sub_comment_count
            cts
            uts
            id
          }
        }
      }
      status
      uts
    }
  }`;

const addNewsComment = `mutation addNewsComment($newsId: Int!, $comment: String!) {
    addNewsComment(newsId: $newsId, comment: $comment) {
      comment
      cts
      message
      num_downvotes
      num_upvotes
      rcvd_downvotes
      rcvd_upvotes
      s2_users {
        address1
        address2
        bio
        birthdate
        cellphone {
          country_code
          country_name
          phone_number
        }
        city
        cts
        email_details {
          isEmailVerified
          value
        }
        emailaddress
        firstname
        fullname
        guest_device_id
        homephone {
          country_code
          country_name
          homephone_verified
          phone_number
        }
        id
        id_s2_pc_alerts
        id_s2_pc_portfolio
        id_s2_pc_user_audit_status
        id_s2_pc_user_portfolio
        id_s2_social_login_types
        id_s2_states
        id_s2_user_privacy_settings
        id_s2_user_scores
        id_s2_user_status
        id_s2_user_type
        lastname
        matrix_detail {
          access_token
          device_id
          user_id
        }
        middlename
        mobile_details {
          country_code
          country_name
          isMobileVerified
          phone_number
        }
        profilephoto
        social_id
        thumb
        username
        uts
        workphone {
          country_code
          country_name
          phone_number
          workphone_verified
        }
      }
    }
  }`;

const newsListingBasedOnTickerSymbol = `query newsListingBasedOnTickerSymbol($pageNumber: Int, $pageSize: Int, $tickerSymbol: String!) {
    newsListingBasedOnTickerSymbol(pageNumber: $pageNumber, pageSize: $pageSize, tickerSymbol: $tickerSymbol) {
      data {
        response{
          id
          is_down_vote_given
          is_up_vote_given
          news_content
          news_image
          news_publish_date
          news_title
          news_url
          percentage_change
          source
          symbol
          thumb
          ticker_details{
            ticker_id
            ticker_image
            ticker_name
            ticker_symbol
          }
          total_comments
          total_down_vote
          total_up_vote
          uts
        }
      }
      message
      status
      uts
    }
  }`;

const getNewsDetails = `query getNewsDetails($newsId: Int!) {
    getNewsDetails(newsId: $newsId) {
      cts
      data {
        response{
          id
          is_down_vote_given
          is_up_vote_given
          news_content
          news_image
          news_publish_date
          news_title
          news_url
          percentage_change
          source
          symbol
          thumb
          ticker_details{
            ticker_id
            ticker_image
            ticker_name
            ticker_symbol
          }
          total_comments
          total_down_vote
          total_up_vote
          uts
        }
      }
      message
      status
      uts
    }
  }`;

const deleteNewsCommentAndSubComment = `mutation deleteNewsCommentAndSubComment($commentId:Int, $subCommentId:Int){
    deleteNewsCommentAndSubComment(commentId:$commentId, subCommentId:$subCommentId){
      status
      message
      }
  }
  `;

const addNewsCommentAndSubComment = `mutation addNewsCommentAndSubComment($comment:String!, $newsId:Int){
  addNewsCommentAndSubComment(comment:$comment, newsId:$newsId){
    status
    message
      response{
        firstname
        user_fullname
        username
        user_id
        comment
        cts
      }
    }
}
`;

const editNewsCommentsAndSubComment = `mutation editNewsCommentsAndSubComment($comment: String!, $commentId:Int,$newsId:Int, $subCommentId:Int){
  editNewsCommentsAndSubComment(comment:$comment, commentId:$commentId,newsId:$newsId, subCommentId:$subCommentId){
    status
    message
    }
}
`;

const getNewsUpvoteDownvoteList = `query getNewsUpvoteDownvoteList($newsId: Int!, $vote:String!){
  getNewsUpvoteDownvoteList(newsId:$newsId, vote:$vote){
    status
    message
    data{
      full_name
      is_account_private
      is_blocked
      is_follow
      is_requested
      thumb
      user_id
      username
      vote_status
    }
    }
}
`;

const newsQuery = {
	getAllNewsDetails,
	newsUpVoteDownVote,
	getNewsComments,
	addNewsComment,
	newsListingBasedOnTickerSymbol,
	getNewsDetails,
	deleteNewsCommentAndSubComment,
	addNewsCommentAndSubComment,
	editNewsCommentsAndSubComment,
	getNewsUpvoteDownvoteList
};

export default newsQuery;
