import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Query from 'app/main/query/user';
import Icon from '@material-ui/core/Icon';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { styled } from '@material-ui/core/styles';
import _ from '@lodash';
import { closeHelpDialog, getHelpForAccount } from 'app/auth/store/userSlice';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}));

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={
			<Icon className="text-black" sx={{ fontSize: '0.9rem', padding: '0px' }}>
				keyboard_arrow_down
			</Icon>
		}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper': {
		padding: '0'
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		padding: 0
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

function HelpDialog() {
	const dispatch = useDispatch();

	const helpData = useSelector(({ userMenuReducer }) =>
		userMenuReducer?.user?.getHelpForAccountData?.data?.getHelpForAccount?.data?.length > 0
			? userMenuReducer.user.getHelpForAccountData.data.getHelpForAccount.data
			: []
	);

	const appDialog = useSelector(({ userMenuReducer }) => userMenuReducer?.user?.helpDialog);

	const [expanded, setExpanded] = useState('panel1');

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	useEffect(() => {
		const payload = {
			query: Query.getHelpForAccount
		};
		dispatch(getHelpForAccount(payload));

		return () => {};
	}, [dispatch]);

	function closeComposeDialog() {
		dispatch(closeHelpDialog());
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				style={{ margin: '0 auto' }}
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full justify-between">
						<Typography variant="subtitle1" color="inherit">
							Help
						</Typography>
						<Button
							className="float-right bg-transparent rounded p-0 w-auto min-w-0"
							variant="contained"
							color="primary"
							type="button"
							onClick={() => {
								dispatch(closeHelpDialog());
							}}
						>
							<Icon>close</Icon>
						</Button>
					</Toolbar>
				</AppBar>
				<DialogContent classes={{ root: 'p-16' }} className="" style={{ height: '50vh', overflowY: 'auto' }}>
					{helpData &&
						helpData.length > 0 &&
						helpData.map((helpcontent, index) => (
							<Accordion
								expanded={expanded === `panel${index}`}
								onChange={handleChange(`panel${index}`)}
								key={index}
								className="border-none bg-grey-100 mb-10 p-16 rounded-8"
							>
								<AccordionSummary
									aria-controls="panel1d-content"
									id="panel1d-header"
									className="p-0 bg-grey-100 border-none help-heading w-full block m-0 min-h-0 font-semibold cursor-pointer"
								>
									<Typography className="font-semibold cursor-pointer">
										{helpcontent?.title}
									</Typography>
								</AccordionSummary>
								<AccordionDetails className="p-0 bg-grey-100 border-none">
									<Typography style={{ textAlign: 'justify' }}>{helpcontent?.answer}</Typography>
								</AccordionDetails>
							</Accordion>
						))}
				</DialogContent>
			</Dialog>
		</>
	);
}
export default HelpDialog;
