import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import userService from 'app/services/userService';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_OTHER_ENDPOINT
});

export const userFollowUnfollow = createAsyncThunk('common/userFollowUnfollow', async (data, { dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const getUserUpvoteDownvoteList = createAsyncThunk(
	'common/getUserUpvoteDownvoteList',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getPostCommentByPostId = createAsyncThunk('common/getPostCommentByPostId', async (data, { dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const getPostDataByPostId = createAsyncThunk('common/getPostDataByPostId', async (data, { dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const addPostVote = createAsyncThunk('common/addPostVote', async (data, { dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const addUserBookmarkPost = createAsyncThunk('common/addUserBookmarkPost', async (data, { dispatch }) => {
	instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
	try {
		const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
		const result = await response.data;
		return result;
	} catch (error) {
		const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
		return dispatch(
			showMessage({
				message: errMsg.message,
				variant: 'error'
			})
		);
	}
});

export const addPostCommentAndSubComment = createAsyncThunk(
	'common/addPostCommentAndSubComment',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getPostReportCategoryRecord = createAsyncThunk(
	'common/getPostReportCategoryRecord',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const deletePostCommentAndSubComment = createAsyncThunk(
	'common/deletePostCommentAndSubComment',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const addPostUserReportTopicRecord = createAsyncThunk(
	'common/addPostUserReportTopicRecord',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const editPostCommentAndSubComment = createAsyncThunk(
	'common/editPostCommentAndSubComment',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getNewsUpvoteDownvoteList = createAsyncThunk(
	'common/getNewsUpvoteDownvoteList',
	async (data, { dispatch }) => {
		instance.defaults.headers.common.Authorization = `Bearer ${userService.getAccessToken() || null}`;
		try {
			const response = await instance.post(process.env.REACT_APP_API_OTHER_ENDPOINT, { ...data });
			const result = await response.data;
			return result;
		} catch (error) {
			const errMsg = error.response ? error.response.data : { status: 'fail', message: 'Something went wrong' };
			return dispatch(
				showMessage({
					message: errMsg.message,
					variant: 'error'
				})
			);
		}
	}
);

const commonSlice = createSlice({
	name: 'common',
	initialState: {
		likesListDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		likesListNewsDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		commentDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		},
		reportListDialog: {
			type: 'new',
			props: {
				open: false
			},
			data: null
		}
	},
	reducers: {
		closeLikesListDialog: (state, action) => {
			state.likesListDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openLikesListDialog: (state, action) => {
			state.likesListDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeLikesListNewsDialog: (state, action) => {
			state.likesListNewsDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openLikesListNewsDialog: (state, action) => {
			state.likesListNewsDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeCommentDialog: (state, action) => {
			state.commentDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		openCommentDialog: (state, action) => {
			state.commentDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		openReportListDialog: (state, action) => {
			state.reportListDialog = {
				type: 'new',
				props: {
					open: true
				},
				data: action.payload
			};
		},
		closeReportListDialog: (state, action) => {
			state.reportListDialog = {
				type: 'new',
				props: {
					open: false
				},
				data: action.payload
			};
		},
		resetcommon: (state, action) => {
			state.getUserUpvoteDownvoteListData = {};
			state.getPostCommentByPostIdData = {};
			// state.getPostDataByPostIdData = {};
			state.addUserBookmarkPostData = {};
			state.getPostReportCategoryRecordData = {};
			state.addPostUserReportTopicRecordData = {};
			state.deletePostCommentAndSubCommentData = {};
		}
	},
	extraReducers: {
		[getUserUpvoteDownvoteList.fulfilled]: (state, action) => ({
			...state,
			getUserUpvoteDownvoteListData: action.payload
		}),
		[getNewsUpvoteDownvoteList.fulfilled]: (state, action) => ({
			...state,
			getNewsUpvoteDownvoteListData: action.payload
		}),
		[getPostCommentByPostId.fulfilled]: (state, action) => ({
			...state,
			getPostCommentByPostIdData: action.payload
		}),
		[getPostDataByPostId.fulfilled]: (state, action) => ({
			...state,
			getPostDataByPostIdData: action.payload
		}),
		[addUserBookmarkPost.fulfilled]: (state, action) => ({
			...state,
			addUserBookmarkPostData: action.payload
		}),
		[getPostReportCategoryRecord.fulfilled]: (state, action) => ({
			...state,
			getPostReportCategoryRecordData: action.payload
		}),
		[addPostUserReportTopicRecord.fulfilled]: (state, action) => ({
			...state,
			addPostUserReportTopicRecordData: action.payload
		}),
		[deletePostCommentAndSubComment.fulfilled]: (state, action) => ({
			...state,
			deletePostCommentAndSubCommentData: action.payload
		})
	}
});

export const {
	resetcommon,
	closeLikesListDialog,
	openLikesListDialog,
	closeLikesListNewsDialog,
	openLikesListNewsDialog,
	openCommentDialog,
	closeCommentDialog,
	openReportListDialog,
	closeReportListDialog
} = commonSlice.actions;

export default commonSlice.reducer;
