const socialLogInSignUpV4 = `mutation socialLogInSignUpV4($data: String!) {
  socialLogInSignUpV4(
    data: $data
  ) {
    status
    message
    accessToken
    deviceId
    isEmailVerified
    country_id
    isRegistrationCompleted
    matrixDeviceId
    matrixToken
    matrixUserId
    message
    refreshToken
    smlAccessToken
    smlConnect
    status
    user {
      s2_user_type {
        name
      }
      id_s2_user_type
      id_s2_countries
      address1
      address2
      bio
      birthdate
      cellphone {
        country_code
        country_name
        phone_number
      }
      s2_countries {
        currency
      }
      city
      cts
      email_details {
        isEmailVerified
        value
      }
      isAcceptPolicy
      emailaddress
      firstname
      guest_device_id
      homephone {
        country_code
        country_name
        homephone_verified
        phone_number
      }
      id
      id_s2_pc_alerts
      id_s2_pc_portfolio
      id_s2_pc_user_audit_status
      id_s2_pc_user_bot_subscription
      id_s2_pc_user_portfolio
      id_s2_social_login_types
      id_s2_states
      id_s2_user_privacy_settings
      id_s2_user_scores
      id_s2_user_status
      id_s2_user_type
      lastname
      middlename
      mobile_details {
        country_code
        country_name
        isMobileVerified
        phone_number
      }
      passwordhash
      profilephoto
      thumb
      username
      uts
      workphone {
        country_code
        country_name
        phone_number
        workphone_verified
      }
      ask_me_anything {
        room_id
        room_alias
      }
    }
  }
}`;

const authLoginMutation = `mutation loginV3($data: String!) {
  loginV3(data: $data) {
      accessToken
      isEmailVerified
      isRegistrationCompleted
      refreshToken
      smlAccessToken
      smlConnect
      matrixDeviceId
      matrixToken
      matrixUserId
      message
      status
      user {
          isAcceptPolicy
          id
          firstname
          lastname
          middlename
          emailaddress
          username
          cellphone {
              country_code
              country_name
              phone_number
          }
          profilephoto
          email_details {
              isEmailVerified
              value
          }
         
          address1
          address2
          city
          s2_user_type{
            name
          }
          s2_countries{
            currency
          }
      }
  }
}`;

const addPrivacyPolicy = `mutation addPrivacyPolicy($status: Boolean!) {
  addPrivacyPolicy(status: $status) {
    message
    status
    privacy_policy
  }
}`;

const appUserForgetPassword = `mutation appUserForgetPassword($email: String!) {
  appUserForgetPassword(email: $email) {
    message
    status
  }
}`;
const forgotPassword = `query forgotPassword($email: String!, $botId: Int){
  forgotPassword(email: $email, botId: $botId){
    status
    message
  }
}
`;

const verifyOTP = `mutation verifyOTP($email: String!, $isForgetPasswordOTP: Boolean, $otp: Int!, $botId: Int){
  verifyOTP(email: $email, isForgetPasswordOTP: $isForgetPasswordOTP, otp: $otp, botId: $botId){
    status
    message
  }
}
`;

const setNewPassword = `mutation setNewPassword($data: String!){
  setNewPassword(data: $data){
    status
    message
  }
}
`;

const verifyToken = `mutation verifyToken($token: JWT!){
  verifyToken(token: $token){
    status
    message
    data{
    matrix_id
    matrix_user_id
    id
    firstname
    lastname
    emailaddress
      username
      cts
      uts
      profilephoto
      s2_user_type{
        name
      }
      s2_countries {
        currency
      }
      token
    }
  }
}
`;

const signup = `mutation signup($email: String!, $pass: String!,$type :String ,$firstname :String!,$middleName: String,$lastname :String!,$phoneCountryCode: Int,$phoneNumber: String,$countryCode: Int) {
    signup(data: { email: $email, password: $pass , type:$type , firstname:$firstname,middleName:$middleName, lastname:$lastname, phoneCountryCode:$phoneCountryCode,phoneNumber:$phoneNumber, countryCode:$countryCode  }) {
      accessToken
      matrixToken
      message
      status
      user {
        emailaddress
        id
      }
    }
  }`;

const signupV3 = `mutation signupV3($data: String!) {
  signupV3(data: $data) {
    accessToken
    matrixToken
    message
    status
    user {
      emailaddress
      id
    }
  }
}`;

const getDevicesLocationData = `mutation getDevicesLocationData($ip_address: String!){
    getDevicesLocationData(ip_address: $ip_address){
      message
      status
      data{
        country
        country_code
        id
      }
    }
  }`;

const checkEmail = `query checkEmail($email: String!) {
    checkEmail(email: $email){
      available
      message
      status
    }
  }`;

const checkUsername = `query checkUsername($username:String!, $userId:Int){
    checkUsername(username:$username, userId:$userId){
      status
      message
      available
    }
  }
`;

const getBotRatingOverView = `query getBotRatingOverView($botId: Int!) {
    getBotRatingOverView(data: { botId: $botId }) {
      id
      response {
        rating_total_count
        five_star_percentage
        one_star_percentage
        rating_total_user_count
        two_star_percentage
        three_star_percentage
        four_star_percentage
        avg_out_five
      }
    }
  }`;

const logOut = `mutation logout($userId: Int!, $token: String!) {
    logout(data: { userId: $userId, token: $token }) {
      status
    }
  }`;

const getStockDashboard = `query {
    getStockDashboard {
      cts
      data {
        countriesId
        groupId
        id
        response {
          info
          list {
            attachment_data {
              file_url
              id
              id_s2_attachment_types
            }
            chart_data
            comments
            company_name
            country
            date
            desc
            description
            down_vote
            f_name
            full_name
            i_img
            i_name
            i_prev_close_value
            i_value
            id
            img
            is_account_private
            is_bookmark
            is_down_vote
            is_down_vote_given
            is_follow
            is_up_vote
            is_up_vote_given
            is_user_down_vote
            is_user_up_vote
            is_watched
            num_followers
            num_posts
            p_change
            mention_user_list {
              id
              user_name
            }
            mention_stock_list {
              id
              stock_name
              ticker_id
              ticker_symbol
            }
            post_poll_data {
              expiration_time
              given_answer
              id
              is_answer_given
              options {
                id
                option
                result
              }
              pollType
              poll_status
              question
              total_user_votes
            }
            news_data {
              news_content
              news_image
              news_publish_date
              news_title
              news_url
              ticker_id
              ticker_image
              ticker_name
              ticker_symbol
              ticker_details {
                ticker_id
                ticker_image
                ticker_name
                ticker_symbol
              }
            }
            post_type
            prev_close_change
            price
            s_score
            shared_url
            ticker_id
            ticker_img
            ticker_name
            ticker_prev_close_price
            ticker_price
            ticker_symbol
            thumb
            type
            u_img
            u_name
            up_vote
            update_date
            user_id
            user_image
            user_img
            user_name
          }
          position
          title
          type
        }
        userId
      }
  
      message
      status
      uts  
  
    }
  }
  `;
const guestLoginSignup = `mutation guestSignInSignUp($deviceId: String!, $deviceType: Int!) {
    guestSignInSignUp(data: { deviceId: $deviceId, deviceType: $deviceType }) {
      accessToken
      isRegistrationCompleted
      matrixDeviceId
      matrixToken
      matrixUserId
      refreshToken
    }
  }`;

const getInfluencerList = `query getInfluencerList($pageNumber: Int, $pageSize: Int) {
    getInfluencerList(pageNumber: $pageNumber, pageSize: $pageSize) {
      status
      message
      data {
        cts
        response {
          user_id
          user_img
          user_name
          s_score
          num_posts
          num_followers
          is_follow
          full_name
          card_style {
            bg {
              color
            }
            btn_f {
              color
            }
          }
        }
      }
    }
  }`;

const getPerformanceList = `query getPerformanceList($pageNumber: Int, $pageSize: Int) {
    getPerformanceList(pageNumber: $pageNumber, pageSize: $pageSize) {
      status
      message
      data {
        cts
        response {
          user_id
          user_img
          user_name
          s_score
          p_change
          num_posts
          num_followers
          is_follow
          full_name
          chart_data
        }
      }
    }
  }`;

const userFollowUnfollow = `mutation userFollowUnfollow($userId: [Int!]!, $isFollow: Boolean!) {
    userFollowUnfollow(userId: $userId, isFollow: $isFollow) {
      message
      status
    }
  }`;

const acceptAndDeleteFollowRequestById = `mutation acceptAndDeleteFollowRequestById($isAccept: Boolean!, $userId: Int!) {
  acceptAndDeleteFollowRequestById(isAccept: $isAccept, userId: $userId) {
      message
      status
    }
}`;

const getFollowRequestByUserId = `query getFollowRequestByUserId($userId: [Int!]!, $isFollow: Boolean!) {
  getFollowRequestByUserId(userId: $userId, isFollow: $isFollow) {
      message
      status
    }
}`;

const addPostVote = `mutation addPostVote($isDownVote: Boolean!, $isUpVote: Boolean!,$postId: Int!) {
  addPostVote(isDownVote: $isDownVote, isUpVote: $isUpVote,postId: $postId) {
      message
      status
    }
}`;

const getFollowersAndFollowingList = `query getFollowersAndFollowingList(
  $matrixUserId: String
  $userId: Int
  $pageSize: Int
  $pageNumber: Int
  $search: String
  $type: String
) {
  getFollowersAndFollowingList(
    matrixUserId: $matrixUserId
    userId: $userId
    pageSize: $pageSize
    pageNumber: $pageNumber
    search: $search
    type: $type
  ) {
    status
    message
    data {
      is_follow
      firstname
      lastname
      id
      emailaddress
      thumb
      username
      is_blocked
      matrix_id
      is_requested
      is_account_private
    }
  }
}
`;

const getProfile = `query getProfile($userId:Int) {
    getProfile(userId:$userId) {
      data {
        api_key
        organization_name
        id
        firstname
        lastname
        middlename
        profilephoto
        thumb
        birthdate
        id_s2_user_type
        s2_user_type{
          name
        }
        s2_user_status{
          status
        }
        s2_countries {
          currency
        }
        id_s2_user_status
        id_s2_countries
        username
        cts
        emailaddress
        bio
        email_details {
          isEmailVerified
        }
        workphone {
          country_code
          country_name
          phone_number
        }
        cellphone {
          country_code
          country_name
          phone_number
        }
      }
    }
  }`;

const sendRegisterOtp = `mutation sendRegisterOtp($email: String!) {
    sendRegisterOtp(email: $email) {
      message
      status
    }
  }`;

const verifyRegisterOtp = `mutation verifyRegisterOtp(
    $email: String!
    $isForgetPasswordOTP: Boolean!
    $otp: Int!
  ) {
    verifyRegisterOtp(
      email: $email
      isForgetPasswordOTP: $isForgetPasswordOTP
      otp: $otp
    ) {
      message
      status
    }
  }`;

const getPolicyType = `query getPolicyType($pageNumber: Int!, $pageSize: Int!) {
    getPolicyType(pageNumber: $pageNumber, pageSize: $pageSize) {
      data {
        id
        type
      }
    }
  }`;

const getPolicyByPolicyId = `query getPolicyByPolicyId($policyId: Int!) {
    getPolicyByPolicyId(policyId: $policyId) {
      data {
        description
      }
    }
  }`;

const socialLogInSignUp = `mutation socialLogInSignUp($socialId: String!, $socialType: Int!) {
    socialLogInSignUp(data: { socialId: $socialId, socialType: $socialType }) {
      accessToken
      deviceId
      isEmailVerified
      isRegistrationCompleted
      matrixDeviceId
      matrixToken
      matrixUserId
      message
      refreshToken
      status
      user {
        address1
        address2
        bio
        birthdate
        cellphone {
          country_code
          country_name
          phone_number
        }
        city
        cts
        email_details {
          isEmailVerified
          value
        }
        emailaddress
        firstname
        guest_device_id
        homephone {
          country_code
          country_name
          homephone_verified
          phone_number
        }
        id
        id_s2_pc_alerts
        id_s2_pc_portfolio
        id_s2_pc_user_audit_status
        id_s2_pc_user_bot_subscription
        id_s2_pc_user_portfolio
        id_s2_social_login_types
        id_s2_states
        id_s2_user_privacy_settings
        id_s2_user_scores
        id_s2_user_status
        id_s2_user_type
        lastname
        matrix_detail {
          access_token
          device_id
          user_id
        }
        middlename
        mobile_details {
          country_code
          country_name
          isMobileVerified
          phone_number
        }
        passwordhash
        profilephoto
        social_id
        thumb
        username
        uts
        workphone {
          country_code
          country_name
          phone_number
          workphone_verified
        }
      }
    }
  }
  
  `;
const getUserDetailById = `query getUserDetailById($id:Int, $userName:String) {
    getUserDetailById(id: $id, userName: $userName) {
      message
      status
      data {
          response {
            api_key
            bio
            follow_back
            follows_requested
            is_follow
            is_portfolio_private
            is_requested
            follows_requested
            follow_back 
            isBlock
            is_account_private
            userId
            num_post
            username
            user_fullname
            profilephoto
            num_followers
            num_following
            matrix_id
          }
          s2_users{
            address1
            address1
            bio
            birthdate
            matrix_user_id
            matrix_id
            city
          }
        }
        }
      }  
  `;
const updateProfile = `mutation uploadFile(
    $file: Upload
    $emailaddress: String
    $firstname: String
    $middlename: String
    $lastname: String
    $bio: String
    $username: String
    $organization_name: String
    $country_code: Int
    $phone_number: String
    $isRemoved: Boolean
  ) {
    updateProfile(
      data: {
        emailaddress: $emailaddress
        firstname: $firstname
        middlename: $middlename
        lastname: $lastname
        bio: $bio
        isRemoved: $isRemoved
        username: $username
        organization_name: $organization_name
        cellphone:{
          country_code: $country_code
          phone_number: $phone_number
        }
      }
      file: $file
    ) {
      data {
        firstname
        profilephoto
        thumb
      }
      message
      status
    }
  }      
  `;
const updatePasswords = `mutation resetPassword(
  $password: String!,
  $token: JWT!
) {
  resetPassword(
    password: $password,
    token: $token
  ){
    message
    status
  }
}
`;

const searchPeople = `query ($name:String, $pageSize:Int, $pageNumber: Int, $userTypeId: Int) {
  searchPeople(name:$name, pageSize:$pageSize, pageNumber:$pageNumber, userTypeId:$userTypeId) {
    data {
      id
      firstname
      lastname
      username
      full_name
      address1
      address2
      bio
      birthdate
      profilephoto
      thumb
      id_s2_user_type
      id_s2_user_status
      cts
      emailaddress
      email_details {
        isEmailVerified
      }
      cellphone {
        country_code
        country_name
        phone_number
      }
      isFollowed
    }
  }
}
`;

const inviteTeam = `
mutation  inviteTeamMember($memberId:[Int!]!,$teamId:Int!){
  inviteTeamMember(memberId:$memberId,teamId:$teamId){
    message
    status
  }
}`;

const verifyUserEmail = `
mutation verifyUserEmail($token: JWT!){
  verifyUserEmail(token:$token ){
    status
    message
  }
}
`;

const verifyEmailAddress = `
mutation verifyEmailAddress($token: JWT!){
  verifyEmailAddress(token:$token ){
    status
    message
  }
}
`;

const getUserStatus = `
query{
  getUserStatus{
    status
    message
    data{
      id
      status
    }
  }
}

`;

const getUserTypeList = `
query{
  getUserTypeList{
    data{
      id
      name
      display_name
    }
  }
}
`;

const searchPeopleOnType = `query searchPeopleOnType($name:String,$pageNumber:Int,$pageSize:Int,$userTypeId:Int,$activeInactiveStatus:Int,$sortBy:String,$sortOrder:OrderDirection){
  searchPeopleOnType(name:$name,pageNumber:$pageNumber,pageSize:$pageSize,userTypeId:$userTypeId,activeInactiveStatus:$activeInactiveStatus,sortBy:$sortBy,sortOrder:$sortOrder){
  message
status
totalCount
data{
  full_name
emailaddress
username
  id
thumb
id_s2_user_status
id_s2_user_type
uts
cts
}
  }
}`;

const enableDisableUserByUserId = `query enableDisableUserByUserId($isEnable:Boolean!, $userId:Int!){
  enableDisableUserByUserId(isEnable:$isEnable, userId:$userId){
message
status
  }
}
`;

const getUserSettingsById = `query getUserSettingsById{
  getUserSettingsById{
message
status
    data{
      email_limit
push_notification_limit
sms_limit
    }
  }
}`;

const updateUserSettingsById = `query updateUserSettingsById($smsLimit:Int,$emailLimit:Int,$pushNotificationLimit:Int,$userId:Int!){
  updateUserSettingsById(smsLimit:$smsLimit,emailLimit:$emailLimit,pushNotificationLimit:$pushNotificationLimit,userId:$userId){
message
status
  }
}`;

const changePasswordByUserId = `mutation changePasswordByUserId($data:String!){
  changePasswordByUserId(data:$data)
  {
    message
    status
  }
}
`;

const getDashboardCount = `query getDashboardCount{
  getDashboardCount{
   data{
    appuserCount
    devUserCount 
    botCount
    feedbackCount
    watchlistCount
    portfolioCount
    alertCount
    postCount
     }
    }
  }
  `;

const getActivity = `query getActivity($pageNumber:Int, $pageSize:Int){
  getActivity(pageNumber:$pageNumber, pageSize:$pageSize){
    status
    message
    data{
      response{
        id
        image
        thumb
        activity_thumb
        comment_id
        full_name
        poll_id
        cts
        title
        user_id
        action_id
        post_id
        is_read
        type
        news_id
        subcomment_id
        username
      }
    }
    }
  }
  `;

const deleteActivity = `mutation deleteActivity($activityId:Int, $clearAll: Boolean){
    deleteActivity(activityId:$activityId, clearAll:$clearAll){
      status
      message
      cts
      }
    }
    `;

const getSetUserSetting = `query getSetUserSetting($publicPortfolioStatus:String!){
  getSetUserSetting(publicPortfolioStatus:$publicPortfolioStatus){
     message
     status 
     publicPortfolioStatus
    }
  }
    `;

const enableDisableNotification = `mutation enableDisableNotification($status:NotificationStatusEnum){
    enableDisableNotification(status:$status){
        message
        status
        enableDisableStatus
      }
    }
  `;

const enableDisableShowPriceChangeInPercentage = `mutation enableDisableShowPriceChangeInPercentage($status:Boolean){
    enableDisableShowPriceChangeInPercentage(status:$status){
        message
        status
        show_price_change_in_percentage
      }
    }
  `;

const getAccountTypeAndUpdateType = `mutation getAccountTypeAndUpdateType($status:Boolean){
    getAccountTypeAndUpdateType(status:$status){
        message
        status
        private_account
      }
    }
`;

const getblockUsersList = `query getblockUsersList($pageNumber:Int, $pageSize:Int){
  getblockUsersList(pageNumber:$pageNumber, pageSize:$pageSize){
        message
        status
        data{
          full_name
          isFollowed
          firstname
          lastname
          username
          matrix_id
          thumb
          id
          matrix_user_id
          id_s2_user_status
          id_s2_states
          s2_user_status{
            status
          }
        }
      }
    }
`;

const unBlockUsersById = `mutation unBlockUsersById($blockUserId:Int!, $matrixUserId:String!){
  unBlockUsersById(blockUserId:$blockUserId, matrixUserId:$matrixUserId){
        message
        status
      }
    }
`;

const blockUsersById = `mutation blockUsersById($blockUserId:Int!){
  blockUsersById(blockUserId:$blockUserId){
        message
        status
      }
    }
`;

const getUserPostList = `query getUserPostList($pageNumber:Int!, $pageSize:Int!, $userId:Int!){
    getUserPostList(pageNumber:$pageNumber, pageSize:$pageSize, userId:$userId){
      status
    message
    data {
      id
      response {
        id
        date
        desc
        description
        thumb
        is_bookmark
        title
        up_vote
        user_id
        comments
        down_vote
        full_name
        post_type
        user_name
        shared_url
        user_image
        is_user_up_vote
        is_user_down_vote
        mention_user_list {
          id
          user_name
        }
        mention_stock_list {
          id
          stock_name
          ticker_id
          ticker_symbol
        }
        attachment_data {
          id
          id_s2_attachment_types
          file_url
          thumb_url
        }
        post_poll_data {
          expiration_time
          given_answer
          id
          is_answer_given
          options {
            id
            option
            result
          }
          pollType
          poll_status
          question
          total_user_votes
        }
        news_data {
          news_content
          news_image
          news_publish_date
          news_title
          news_url
          ticker_id
          ticker_image
          ticker_name
          ticker_symbol
          ticker_details{
            ticker_id
            ticker_image
            ticker_name
            ticker_symbol
          }
          thumb
        }
      }
    }
      }
    }
    `;
const getUserUpvoteDownvoteList = `query getUserUpvoteDownvoteList($postId:Int!, $vote:String!){
      getUserUpvoteDownvoteList(postId:$postId, vote:$vote){
        status
        message
        data{
          full_name
          is_account_private
          is_blocked
          is_follow
          is_requested
          thumb
          user_id
          username
          vote_status
        }
        }
      }
      `;
const addUserBookmarkPost = `mutation addUserBookmarkPost($is_bookmark:Boolean!, $postId:Int!){
    addUserBookmarkPost(is_bookmark:$is_bookmark, postId:$postId){
      status
      message
      }
  }
`;
const getPostCommentByPostId = `query getPostCommentByPostId($pageNumber:Int, $pageSize:Int!, $postId:Int!){
  getPostCommentByPostId(pageNumber:$pageNumber, pageSize:$pageSize, postId:$postId){
    status
    message
    cts
    data{
      response{
        firstname
        full_name
        isFollowed
        isRemoved
        is_bookmark
        is_down_vote_given
        is_up_vote_given
        lastname
        num_upvotes
        total_sub_comment_count
        total_sub_comments
        user_image
        username
        user_id
        id
        time
        comment
        comment_status
        cts
        uts
        total_sub_comment_count
        total_sub_comments
        thumb
        profilephoto
      }
      s2_users{
        full_name
        firstname
        isFollowed
        isRemoved
        is_bookmark
        is_down_vote_given
        is_up_vote_given
        thumb
        user_id
      }
    }
  }
}
`;
const getPostDataByPostId = `query getPostDataByPostId($postId:Int, $sharedUrl:String){
  getPostDataByPostId(postId:$postId, sharedUrl:$sharedUrl){
    status
    message
    data{
      response{
        id
        date
        desc
        description
        thumb
        is_bookmark
        title
        up_vote
        user_id
        comments
        down_vote
        full_name
        post_type
        user_name
        shared_url
        user_image
        is_user_up_vote
        is_user_down_vote
        mention_user_list {
          id
          user_name
        }
        mention_stock_list {
          id
          stock_name
          ticker_id
          ticker_symbol
        }
        attachment_data {
          id
          id_s2_attachment_types
          file_url
          thumb_url
        }
        post_type
        post_poll_data {
          expiration_time
          given_answer
          id
          is_answer_given
          options {
            id
            option
            result
          }
          pollType
          poll_status
          question
          total_user_votes
        }
        news_data {
          news_content
          news_image
          news_publish_date
          news_title
          news_url
          ticker_id
          ticker_image
          ticker_name
          ticker_symbol
          ticker_details {
            ticker_id
            ticker_image
            ticker_name
            ticker_symbol
          }
        }
        news_data {
          news_content
          news_image
          news_publish_date
          news_title
          news_url
          ticker_id
          ticker_image
          ticker_name
          ticker_symbol
          ticker_details {
            ticker_id
            ticker_image
            ticker_name
            ticker_symbol
          }
        }
      }
    }    
  }
}
`;
const addPostCommentAndSubComment = `mutation addPostCommentAndSubComment($comment:String, $postId:Int!){
  addPostCommentAndSubComment(comment:$comment, postId:$postId){
    status
    message
    data{
      response{
        id
        post_comment_id
        firstname
        username
        user_id
        comment
        cts
      }
    }
    }
}
`;

const deletePostCommentAndSubComment = `mutation deletePostCommentAndSubComment($commentId:Int!, $subComment:Boolean!){
  deletePostCommentAndSubComment(commentId:$commentId, subComment:$subComment){
    status
    message
    }
}
`;

const editPostCommentAndSubComment = `mutation editPostCommentAndSubComment($comment: String, $commentId:Int!,$postId:Int, $subCommentId:Int,$type:String){
  editPostCommentAndSubComment(comment:$comment, commentId:$commentId,postId:$postId, subCommentId:$subCommentId, type:$type){
    status
    message
    }
}
`;

const getPostReportCategoryRecord = `query getPostReportCategoryRecord($pageSize:Int, $pageNumber:Int){
  getPostReportCategoryRecord(pageSize:$pageSize, pageNumber:$pageNumber){
    status
    message
    data{
      _count{
        s2_post_report_subcategory
      }
      active
      category
      subcategory
      id
    }
    totalCount
    }
}
`;
const addPostUserReportTopicRecord = `mutation addPostUserReportTopicRecord($categoryId:Int!, $postId:Int!){
  addPostUserReportTopicRecord(categoryId:$categoryId, postId:$postId){
    status
    message
  }
}
`;
const getRequestedUser = `query getRequestedUser($pageNumber:Int, $pageSize:Int, $searchText:String){
  getRequestedUser(pageNumber:$pageNumber, pageSize:$pageSize, searchText:$searchText){
    data{
      s2_users_s2_follow_requests_id_s2_usersTos2_users{
        id
        firstname
        lastname
        username
        username
        profilephoto
        thumb
      }
    }
    status
    message
  }
}
`;

const accountDeactivateAndDeleteById = `mutation accountDeactivateAndDeleteById($type:String!){
  accountDeactivateAndDeleteById(type:$type){
    status
    message
  }
}
`;

const getTeamInvites = `query getTeamInvites{
  getTeamInvites{
    status
    message
    data{
      s2_team{
        banner_image
        banner_url
        description
        id
        id_s2_team_status
        id_s2_team_types
        id_s2_users
        image
        name
        short_description
        thumb
      }
    }
  }
}
`;

const updateInviteRequestStatus = `mutation updateInviteRequestStatus($isAccept: Boolean!, $teamId: Int!){
  updateInviteRequestStatus(isAccept:$isAccept, teamId:$teamId){
    status
    message
  }
}
`;

const createUniqueApiKey = `mutation createUniqueApiKey{
  createUniqueApiKey{
    status
    message
    data{
      api_key
    }
  }
}
`;

const getAboutUsAndContactUsDetails = `query getAboutUsAndContactUsDetails( $id: Int!){
  getAboutUsAndContactUsDetails(id:$id){
    data{
      active
      description
      id
      type
    }
    status
    message
  }
}
`;

const getHelpForAccount = `query getHelpForAccount{
  getHelpForAccount{
    data{
      active
      answer
      id
      title
    }
    status
    message
  }
}
`;

const Query = {
	socialLogInSignUpV4,
	authLoginMutation,
	signupV3,
	signup,
	getDevicesLocationData,
	checkEmail,
	checkUsername,
	getBotRatingOverView,
	getUserDetailById,
	logOut,
	guestLoginSignup,
	userFollowUnfollow,
	getFollowRequestByUserId,
	acceptAndDeleteFollowRequestById,
	addPostVote,
	getFollowersAndFollowingList,
	getProfile,
	getInfluencerList,
	getPerformanceList,
	sendRegisterOtp,
	verifyRegisterOtp,
	getPolicyType,
	getPolicyByPolicyId,
	socialLogInSignUp,
	addPrivacyPolicy,
	appUserForgetPassword,
	updateProfile,
	verifyToken,
	forgotPassword,
	verifyOTP,
	setNewPassword,
	updatePasswords,
	searchPeople,
	inviteTeam,
	verifyUserEmail,
	verifyEmailAddress,
	getUserStatus,
	getUserTypeList,
	searchPeopleOnType,
	enableDisableUserByUserId,
	getUserSettingsById,
	updateUserSettingsById,
	changePasswordByUserId,
	getDashboardCount,
	getStockDashboard,
	getActivity,
	deleteActivity,
	getSetUserSetting,
	enableDisableNotification,
	enableDisableShowPriceChangeInPercentage,
	getAccountTypeAndUpdateType,
	getblockUsersList,
	unBlockUsersById,
	blockUsersById,
	getUserPostList,
	getUserUpvoteDownvoteList,
	addUserBookmarkPost,
	getPostCommentByPostId,
	getPostDataByPostId,
	addPostCommentAndSubComment,
	deletePostCommentAndSubComment,
	editPostCommentAndSubComment,
	getRequestedUser,
	accountDeactivateAndDeleteById,
	getPostReportCategoryRecord,
	addPostUserReportTopicRecord,
	getTeamInvites,
	updateInviteRequestStatus,
	createUniqueApiKey,
	getAboutUsAndContactUsDetails,
	getHelpForAccount
};

export default Query;
