import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import { motion } from 'framer-motion';
import Paper from '@material-ui/core/Paper';
import { useCallback, useEffect, useState, useRef } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import * as yup from 'yup';
import BotQuery from 'app/main/query/bot';
import Query from 'app/main/query/user';
import countryQuery from 'app/main/query/country';
import history from '@history';
import reducer from './store';
import TeamsDialog from '../teams/TeamsDialog';
import { openNewTeamsDialog } from '../teams/store/teamsSlice';
import {
	createBots,
	closeNewMyBotsDialog,
	checkNameHandlerExist,
	openTeamDialog,
	closeTeamDialog,
	getAvailableCountry,
	checkUsername,
	getPermissionList,
	getTeamDetailsByTeamTypeId,
	resetBot
} from './store/myBotsSlice';
import { botDefaultImage, resizeImage, capitalize } from '../../../services/commonService';

const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const defaultValues = {
	name: '',
	personalGroup: '',
	botUserName: '',
	handler: '',
	team: 'Select team Name',
	category: 'Selectbot',
	password: '',
	description: '',
	cnf_password: ''
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup
		.string()
		.required('Name is required')
		.matches(/^[a-zA-Z0-9 ]+$/, '* Special characters not allowed in bot Name '),
	handler: yup
		.string()
		.required('Handler is required')
		.matches(/^[A-Z0-9a-z]+$/, 'Must be only digits and character'),
	botUserName: yup
		.string()
		.required('Bot Username is required')
		.matches(/^[a-zA-Z0-9]+$/, '* Special characters and Space not allowed in bot Username '),
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters at minimum]')
		.matches(
			/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
			'Password must contain one uppercase, one lowercase one number and one special character'
		),
	cnf_password: yup
		.string()
		.required('Confirm Password is required')
		.oneOf([yup.ref('password'), null], 'Confirm Password must match with Password'),
	description: yup.string().required('Description is required'),
	shortDescription: yup
		.string()
		.required('Short Description is required')
		.max(24, 'Short description is too long - should be 24 characters maximum.'),
	category: yup.string().required()
});

function MyBotsDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [botStatus, setBotStatus] = useState(1);

	const checkBotNameUniqData = useSelector(({ myBotsReducer }) =>
		myBotsReducer?.mybots?.checkNameHandlerExistData?.data?.checkNameHandlerExist
			? myBotsReducer.mybots.checkNameHandlerExistData.data.checkNameHandlerExist
			: []
	);

	const botStoreCategoryTypeData = useSelector(({ myBotsReducer }) =>
		myBotsReducer.mybots.botStoreCategoryTypeData &&
		myBotsReducer.mybots.botStoreCategoryTypeData.data &&
		myBotsReducer.mybots.botStoreCategoryTypeData.data.getAllBotsByBotCategory &&
		myBotsReducer.mybots.botStoreCategoryTypeData.data.getAllBotsByBotCategory.data.length > 0
			? myBotsReducer.mybots.botStoreCategoryTypeData.data.getAllBotsByBotCategory.data
			: []
	);

	const appDialogTeam = useSelector(({ myBotsReducer }) => myBotsReducer.mybots.teamDialog);

	const teamNameList = useSelector(
		({ myBotsReducer }) =>
			myBotsReducer?.mybots?.getTeamDetailsByTeamTypeIdData?.data?.getTeamDetailsByTeamTypeId?.totalCount
	);

	const botData = useSelector(({ myBotsReducer }) =>
		myBotsReducer.mybots &&
		myBotsReducer.mybots.botsData &&
		myBotsReducer.mybots.botsData.data &&
		myBotsReducer.mybots.botsData.data.getBotByUserId
			? myBotsReducer.mybots.botsData.data.getBotByUserId
			: []
	);

	const contryData = useSelector(({ myBotsReducer }) =>
		myBotsReducer?.mybots?.getAvailableCountryData?.data?.getAvailableCountry?.data?.length > 0
			? myBotsReducer.mybots.getAvailableCountryData.data.getAvailableCountry.data
			: []
	);

	const permissionData = useSelector(({ myBotsReducer }) =>
		myBotsReducer?.mybots?.getPermissionListData?.data?.getPermissionList?.data?.length > 0
			? myBotsReducer.mybots.getPermissionListData.data.getPermissionList.data
			: []
	);

	let teamId;
	if (botData && botData.data) {
		const result = botData.data.filter(item => item.id_s2_team_types === 1);
		if (result && result[0]) {
			teamId = result[0].id;
		}
	}

	const createBotData = useSelector(({ myBotsReducer }) => myBotsReducer.mybots.createBotData);

	const appDialog = useSelector(({ myBotsReducer }) => myBotsReducer.mybots.myBotsDialog);

	const checkUserName = useSelector(({ myBotsReducer }) =>
		myBotsReducer?.mybots?.checkUsernameData?.data?.checkUsername
			? myBotsReducer.mybots.checkUsernameData.data.checkUsername
			: {}
	);

	const { control, watch, setValue, reset, handleSubmit, formState, getValues, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const [copyUserIdSuccess, setCopyUserIdSuccess] = useState('Copy');
	const [copyTokenSuccess, setCopyTokenSuccess] = useState('Copy');
	const [copyRoomIdSuccess, setCopyRoomIdSuccess] = useState('Copy');
	const [copyHomeServerSuccess, setCopyHomeServerSuccess] = useState('Copy');

	const userIdRef = useRef(null);
	const tokenRef = useRef(null);
	const roomIdRef = useRef(null);
	const homeServerRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const scollToRef = useRef();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [approvalDialog, setApprovalDialog] = useState(false);
	// const [teamDialog, setTeamDialog] = useState(false);
	const [approvalUserId, setApprovalUserId] = useState('');
	const [approvalToken, setApprovalToken] = useState('');
	const [personalTeamName, setPersonalTeamName] = useState('');
	const [botSpaceRoomId, setbotSpaceRoomId] = useState('');
	const [homeServerUrl, setHomeServerUrl] = useState('');
	const [teamValue, setteamValue] = useState(1);
	const { isValid, dirtyFields, errors } = formState;
	const [showPassword, setShowPassword] = useState(false);
	const [showCnfPassword, setShowCnfPassword] = useState(false);
	const [countryName, setCountryName] = useState([]);
	const [permissionName, setPermissionName] = useState([]);
	const [defaultValue] = useState([{ name: 'Select country *' }]);
	const [defaultValuePermission] = useState([{ name: 'Select Permission' }]);
	const [countryId, setCountryId] = useState([]);
	const [permissionId, setPermissionId] = useState([]);
	const [countryRequire, setCountryRequire] = useState('');
	const [permissionRequire, setPermissionRequire] = useState('');
	const [imageUpload, setImageUpload] = useState();
	const [featureImageUpload, setFeatureImageUpload] = useState();
	const [featureImagePreview, setFeatureImagePreview] = useState(botDefaultImage);
	const [imagePreview, setImagePreview] = useState(botDefaultImage);
	const [noImgMsg, setNoImgMsg] = useState('');
	const [noCategoryMsg, setNoCategoryMsg] = useState('');
	const [noImgMsgFeature, setNoImgMsgFeature] = useState('');
	const [team, setTeam] = useState(false);
	const [refreshIcon, setrefreshIcon] = useState(false);
	const [botType, setBotType] = useState(1);
	const [inviteMemeberId, setInviteMemeberId] = useState([]);

	const csvData = [
		['UserId:', approvalUserId],
		['Token:', approvalToken],
		['Bot Space RoomId:', botSpaceRoomId],
		['Home Server Url:', homeServerUrl]
	];

	/**
	 * Initialize Dialog with Data
	 */
	const initDialog = useCallback(() => {
		/**
		 * Dialog type: 'edit'
		 */
		if (appDialog.type === 'edit' && appDialog.data) {
			reset({ ...appDialog.data });
		}

		/**
		 * Dialog type: 'new'
		 */
		if (appDialog.type === 'new') {
			reset({
				...defaultValues,
				...appDialog.data
			});
		}
	}, [appDialog.data, appDialog.type, reset]);

	/**
	 * On Dialog Open
	 */
	useEffect(() => {
		if (appDialog.open) {
			initDialog();
		}
	}, [appDialog.open, dispatch, initDialog]);

	useEffect(() => {
		setLoading(false);
		if (
			createBotData &&
			Object.keys(createBotData).length &&
			(createBotData.status === 'fail' || createBotData.status !== 200)
		) {
			enqueueSnackbar(createBotData.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
			setApprovalDialog(false);
		} else if (createBotData && Object.keys(createBotData).length && createBotData.status === 200) {
			setApprovalDialog(true);
			setApprovalUserId(createBotData.data.bot_matrix_detail.user_id);
			setApprovalToken(createBotData.data.bot_matrix_detail.access_token);
			setbotSpaceRoomId(createBotData.data.bot_space_room_id);
			setHomeServerUrl(createBotData.data.bot_matrix_detail.home_server_url);
			// history.push(`/my-bots-detail/${createBotData.data.id}`);
		}
	}, [createBotData, enqueueSnackbar, dispatch]);

	// useEffect(() => {
	// 	const payload = {
	// 		query: BotQuery.getTeamDetailsByTeamTypeId,
	// 		variables: { createdByMe: true, pageNumber: 1, pageSize: 500 }
	// 	};
	// 	dispatch(getTeamDetailsByTeamTypeId(payload));
	// }, [dispatch]);

	useEffect(() => {
		if (
			checkBotNameUniqData &&
			checkBotNameUniqData.isAvailable === false &&
			checkBotNameUniqData.type === 'botName'
		) {
			setError('name', {
				type: 'manual',
				message: 'Name is already exists.'
			});
		}
	}, [checkBotNameUniqData, setError]);

	useEffect(() => {
		if (
			checkBotNameUniqData &&
			checkBotNameUniqData.isAvailable === false &&
			checkBotNameUniqData.type === 'botHandler'
		) {
			setError('handler', {
				type: 'manual',
				message: 'Handler is already exists.'
			});
		}
	}, [checkBotNameUniqData, setError]);

	useEffect(() => {
		const payload = {
			query: countryQuery.getAvailableCountry
		};
		dispatch(getAvailableCountry(payload));
		return () => {
			dispatch(resetBot());
		};
	}, [dispatch]);

	useEffect(() => {
		const payload = {
			query: BotQuery.getPermissionList
		};
		dispatch(getPermissionList(payload));
	}, [dispatch]);

	useEffect(() => {
		if (defaultValues.personalGroup === '' && personalTeamName === '') {
			setError('personalGroup', {
				type: 'manual',
				message: 'Personal Team Name Requierd.'
			});
		}
	}, [setError, personalTeamName]);

	useEffect(() => {
		if (checkUserName && checkUserName.available === false) {
			setError('botUserName', {
				type: 'manual',
				message: 'username is already exists.'
			});
		}
	}, [checkUserName, setError]);

	function handlepersonalGroupKeyPress(event) {
		setPersonalTeamName(event.target.value);
	}

	function copyToClipboardUserId(e) {
		setCopyTokenSuccess('Copy');
		setCopyRoomIdSuccess('Copy');
		setCopyHomeServerSuccess('Copy');
		userIdRef.current.select();
		document.execCommand('copy');
		e.target.focus();
		setCopyUserIdSuccess('Copied!');
	}

	function copyToClipboardToken(e) {
		setCopyUserIdSuccess('Copy');
		setCopyRoomIdSuccess('Copy');
		setCopyHomeServerSuccess('Copy');
		tokenRef.current.select();
		document.execCommand('copy');
		e.target.focus();
		setCopyTokenSuccess('Copied!');
	}
	function copyToClipboardRoomId(e) {
		setCopyUserIdSuccess('Copy');
		setCopyTokenSuccess('Copy');
		roomIdRef.current.select();
		document.execCommand('copy');
		e.target.focus();
		setCopyRoomIdSuccess('Copied!');
	}

	function copyToClipboardHomeServer(e) {
		setCopyUserIdSuccess('Copy');
		setCopyTokenSuccess('Copy');
		setCopyRoomIdSuccess('Copy');
		homeServerRef.current.select();
		document.execCommand('copy');
		e.target.focus();
		setCopyHomeServerSuccess('Copied!');
	}

	/**
	 * Close Dialog
	 */
	function closeComposeDialog() {
		return dispatch(closeNewMyBotsDialog());
	}

	function handleFileChange(e) {
		const file = e.target.files[0];
		if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
			enqueueSnackbar('Only valid .png, .jpg format files', {
				variant: 'error',
				autoHideDuration: 2000
			});
			return;
		}
		const reader = new FileReader();

		reader.readAsBinaryString(file);

		reader.onload = async () => {
			setImagePreview(`data:${file.type};base64,${btoa(reader.result)}`);
			setImageUpload(e.target.files[0]);
			const resizableImage = await resizeImage(file);
		};
		setNoImgMsg('');
	}

	function handleFileChangeFeature(e) {
		const file = e.target.files[0];
		if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
			enqueueSnackbar('Only valid .png, .jpg format files', {
				variant: 'error',
				autoHideDuration: 2000
			});
			return;
		}
		const reader = new FileReader();

		reader.readAsBinaryString(file);

		reader.onload = async () => {
			setFeatureImagePreview(`data:${file.type};base64,${btoa(reader.result)}`);
			setFeatureImageUpload(e.target.files[0]);
			const resizableImage = await resizeImage(file);
		};
		setNoImgMsgFeature('');
	}

	function handleBotCategory(event) {
		if (event.target.value) {
			setNoCategoryMsg('');
		}
	}

	/**
	 * Form Submit
	 */
	function onSubmit(data) {
		if (!imageUpload) {
			setNoImgMsg('Please upload Bot Image');
			scollToRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			return;
		}
		if (!featureImageUpload) {
			setNoImgMsgFeature('Please add Feature Image');
			scollToRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			return;
		}
		if (data.category === 'Selectbot' || !data.category) {
			setNoCategoryMsg('Please add Bot Category');
			return;
		}

		const teamid = parseInt(data.team, 10);
		const categoryid = parseInt(data.category, 10);
		const status = parseInt(botStatus, 10);
		const type = parseInt(data.type, 10);
		if (appDialog.type === 'new' && imageUpload) {
			setLoading(true);
			const payloadData = {
				query: BotQuery.addBot,
				variables: {
					botUserName: data.botUserName,
					image: null,
					featuredImage: null,
					handler: `${data.handler}`,
					name: data.name,
					teamId: teamid,
					password: data.password,
					description: data.description,
					shortDescription: data.shortDescription,
					idS2BotType: parseInt(botType, 10),
					idS2BotStatus: status,
					botCategoryId: categoryid,
					countryCode: countryId,
					permissionId,
					cnf_password: data.cnf_password
				}
			};
			if (!payloadData.variables.teamId) {
				const teamIdValue = parseInt(teamId, 10);
				payloadData.variables.teamId = teamIdValue;
			}
			const dataObj = {
				payloadvalue: payloadData,
				filedata: imageUpload,
				filedatafeature: featureImageUpload
			};

			dispatch(createBots(dataObj));

			setTimeout(() => {
				reset(defaultValues);
				setImagePreview(botDefaultImage);
				setImageUpload('');
				setFeatureImagePreview(botDefaultImage);
				setFeatureImageUpload('');
			}, 1000);
			setNoCategoryMsg('');
		}
	}

	/**
	 * Remove Event
	 */
	function handleRemove() {
		closeComposeDialog();
		reset(defaultValues);
		setImagePreview(botDefaultImage);
		setFeatureImagePreview(botDefaultImage);
	}

	function handleHandlerNameKeyPress(event, textType) {
		let data = { botName: event.target.value };
		if (textType === 'botHandler') {
			data = { searchHandler: event.target.value };
		}
		if (event.target.value) {
			const payloadData = {
				query: BotQuery.checkNameHandlerExist,
				variables: data
			};
			dispatch(checkNameHandlerExist(payloadData));
		}
	}

	function handleChange(event) {
		const {
			target: { value }
		} = event;
		const tempCountryId = [];
		const tempCountryName = [];
		value.map(dataValue => {
			if (dataValue.id) {
				tempCountryId.push(dataValue.id);
				tempCountryName.push(dataValue);
			}
			return true;
		});
		setCountryId(tempCountryId);
		setCountryName(tempCountryName);
		if (tempCountryId.length === 0) {
			setCountryRequire('country require');
		} else {
			setCountryRequire('');
		}
	}

	function handleChangePermission(event) {
		const {
			target: { value }
		} = event;
		const tempPermissionId = [];
		const tempPermissionName = [];
		value.map(dataValue => {
			if (dataValue.id) {
				tempPermissionId.push(dataValue.id);
				tempPermissionName.push(dataValue);
			}
			return true;
		});
		setPermissionId(tempPermissionId);
		setPermissionName(tempPermissionName);
		// if (tempPermissionId.length === 0) {
		// 	setPermissionRequire('Permission require');
		// } else {
		// 	setPermissionRequire('');
		// }
	}

	function handleUserNameKeyPress(event) {
		if (event.target.value) {
			const payloadData = {
				query: Query.checkUsername,
				variables: { username: event.target.value }
			};
			dispatch(checkUsername(payloadData));
		}
	}

	function handleTeamChange(toggle) {
		setTeam(!toggle);
		if (teamNameList === 0) {
			dispatch(openTeamDialog());
		}
	}

	function handleTeamCancel() {
		dispatch(closeTeamDialog());
		setTeam(false);
	}
	function refreshTeam() {
		const payload = {
			query: BotQuery.getTeamDetailsByTeamTypeId,
			variables: { createdByMe: true }
		};
		dispatch(getTeamDetailsByTeamTypeId(payload));
	}

	function handleTypeDropdownChange(event) {
		setBotType(event.target.value);
		setValue('bot_price', '', { shouldDirty: false, shouldValidate: false });
	}

	function noRefCheck(event) {
		if (event) {
			// const inviId = event.id;
			// setInviteMemeberId(inviId);
			// const teamMemsId = event.map(memsData => memsData.s2_team.id);
			setInviteMemeberId(event.id);
		} else {
			setInviteMemeberId();
		}
	}

	async function fakeAction(page, search) {
		const tempOptionsData = [];
		return new Promise((resolve, reject) => {
			const payload = {
				query: BotQuery.getTeamDetailsByTeamTypeId,
				variables: {
					pageNumber: !search ? (Math.ceil(teamNameList / 10) === page ? '' : page + 1) : 1,
					pageSize: 10,
					createdByMe: true,
					searchText: search
				}
			};

			dispatch(getTeamDetailsByTeamTypeId(payload)).then(response => {
				if (
					response &&
					response?.payload &&
					response?.payload.data &&
					response?.payload?.data?.getTeamDetailsByTeamTypeId &&
					response?.payload?.data?.getTeamDetailsByTeamTypeId?.data &&
					response?.payload?.data?.getTeamDetailsByTeamTypeId?.data.length > 0
				) {
					const filteredOptionsArray = response.payload.data.getTeamDetailsByTeamTypeId.data;
					filteredOptionsArray.forEach(record => {
						tempOptionsData.push({
							label: `${record.s2_team.name}`,
							id: record.s2_team.id,
							value: record.s2_team.id
						});
					});
					resolve(tempOptionsData);
				} else {
					resolve();
				}
			});
		});
	}

	const loadOptions = async (search, prevOptions, { page }) => {
		let optionsData = [];
		if (!search) {
			optionsData = await fakeAction(page, search);
		} else {
			optionsData = await fakeAction(page, search);
		}

		if (optionsData && optionsData.length) {
			const filteredOptions = optionsData;

			const hasMore = !search
				? filteredOptions.length + prevOptions.length >= prevOptions.length
				: filteredOptions.length >= 10;
			const slicedOptions = optionsData.slice(optionsData, prevOptions.length + 10);
			return {
				options: slicedOptions,
				hasMore,
				additional: {
					page: !search ? (Math.ceil(teamNameList / 10) === page ? '' : page + 1) : 1
				}
			};
		}

		return {
			options: [],
			hasMore: false,
			additional: {
				page: !search ? (Math.ceil(teamNameList / 10) === page ? '' : page + 1) : 1
			}
		};
	};

	const colourStyles = {
		menuList: styles => ({
			...styles,
			background: '#fff'
		}),
		option: (styles, { isFocused, isSelected }) => ({
			...styles,
			background: isFocused ? '#fff' : isSelected ? '#fff' : undefined,
			color: `#000`,
			zIndex: 1
		}),
		menu: base => ({
			...base,
			zIndex: 100
		})
	};

	return (
		<div>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full">
						<Typography variant="subtitle1" color="inherit">
							{appDialog.type === 'new' ? 'CREATE A NEW BOT' : 'EDIT BOT'}
						</Typography>
					</Toolbar>
				</AppBar>

				<form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col md:overflow-hidden">
					<DialogContent classes={{ root: 'p-24' }} ref={scollToRef}>
						<div className="flex">
							<motion.div className="w-192 h-192 mb-24">
								<Paper
									className="flex flex-col relative items-center justify-center w-full h-full rounded-8 shadow hover:shadow-lg border-2"
									role="button"
								>
									<img className="w-2/4 block rounded max-h-96" src={imagePreview} alt="Bots" />
									<label htmlFor="buttonfile">
										<input
											accept="image/*"
											className="hidden"
											id="buttonfile"
											name="buttonfile"
											type="file"
											onChange={handleFileChange}
											required
										/>
										<Button
											size="small"
											className="rounded mt-4"
											variant="outlined"
											color="primary"
											component="span"
										>
											Upload Bot Image
										</Button>
									</label>
									{noImgMsg && <span className="text-red block mt-16">{noImgMsg}</span>}
								</Paper>
							</motion.div>
							<motion.div className="w-192 h-192 mb-24 ml-24">
								<Paper
									className="flex flex-col relative items-center justify-center w-full h-full rounded-8 shadow hover:shadow-lg border-2"
									role="button"
								>
									<img
										className="w-2/4 block rounded max-h-96"
										src={featureImagePreview}
										alt="Bots"
									/>
									<label htmlFor="buttonfilefeature">
										<input
											accept="image/*"
											className="hidden"
											id="buttonfilefeature"
											name="buttonfilefeature"
											type="file"
											onChange={handleFileChangeFeature}
											required
										/>
										<Button
											size="small"
											className="rounded mt-4"
											variant="outlined"
											color="primary"
											component="span"
										>
											Upload Feature Image
										</Button>
									</label>
									{noImgMsgFeature && <span className="text-red block mt-16">{noImgMsgFeature}</span>}
								</Paper>
							</motion.div>
						</div>
						<div className="flex">
							<Controller
								control={control}
								name="name"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Name"
										id="name"
										onBlur={e => {
											handleHandlerNameKeyPress(e, 'botName');
										}}
										error={!!errors.name}
										helperText={errors?.name?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
						</div>
						<div className="flex">
							<Controller
								control={control}
								name="handler"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Handler"
										id="handler"
										onBlur={e => {
											handleHandlerNameKeyPress(e, 'botHandler');
										}}
										error={!!errors.handler}
										helperText={errors?.handler?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
						</div>
						<div className="flex">
							<Controller
								control={control}
								name="botUserName"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Bot User Name"
										id="botUserName"
										error={!!errors.botUserName}
										helperText={errors?.botUserName?.message}
										onKeyUp={handleUserNameKeyPress}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
						</div>
						<div className="block w-full">
							<Controller
								control={control}
								name="selectcontry"
								render={({ field }) => (
									<>
										<Select
											{...field}
											id="selectcontry"
											variant="outlined"
											className="mb-24 w-full"
											multiple
											label="Select Country"
											required
											value={countryName.length === 0 ? defaultValue : countryName}
											onChange={handleChange}
											renderValue={selected => {
												const selectedData = [];
												selected.map(name => selectedData.push(name.name));
												return selectedData.join(', ');
											}}
										>
											<MenuItem disabled checked>
												Select Country
											</MenuItem>
											{contryData.map(cData => (
												<MenuItem key={cData.name} value={cData}>
													<Checkbox checked={countryName.indexOf(cData) > -1} />
													<ListItemText primary={cData.name} />
												</MenuItem>
											))}
										</Select>
										{countryRequire && (
											<div className="relative -top-20" style={{ color: '#f44336' }}>
												{countryRequire}
											</div>
										)}
									</>
								)}
							/>
						</div>
						<div className="block w-full">
							<Controller
								control={control}
								name="selectpermission"
								render={({ field }) => (
									<>
										<Select
											{...field}
											id="selectpermission"
											variant="outlined"
											className="mb-24 w-full"
											multiple
											label="Select Bot Permission"
											value={
												permissionName.length === 0 ? defaultValuePermission : permissionName
											}
											onChange={handleChangePermission}
											renderValue={selected => {
												const selectedData = [];
												selected.map(name => selectedData.push(name.name));
												return selectedData.join(', ');
											}}
										>
											<MenuItem disabled checked>
												<em> Select Bot Permission </em>
											</MenuItem>
											{permissionData.map(cData => (
												<MenuItem key={cData.name} value={cData}>
													<Checkbox checked={permissionName.indexOf(cData) > -1} />
													<ListItemText primary={cData.name} />
												</MenuItem>
											))}
										</Select>
										{/* {permissionRequire && (
											<div className="relative -top-20" style={{ color: '#f44336' }}>
												{permissionRequire}
											</div>
										)} */}
									</>
								)}
							/>
						</div>
						<div className="flex w-full relative">
							<Typography
								className={`items-center flex w-full justify-between bg-grey-100 rounded-8 px-12 mb-12 border ${
									teamNameList === 0 ? 'mr-24 ' : ''
								}`}
								component="span"
							>
								<span className="font-semibold text-sm text-black md:tracking-wide">Team</span>

								<Switch
									checked={team === true}
									onClick={() => handleTeamChange(team)}
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									className="pr-6 w-52"
								/>
							</Typography>
							{teamNameList === 0 && (
								<Icon
									className={`text-16 ml-5 mt-10 absolute right-0 cursor-pointer text-amber-500 font-bold ${
										refreshIcon ? `animate-spin` : ''
									}`}
									title="Refresh Token"
									onClick={() => {
										refreshTeam();
									}}
								>
									refresh
								</Icon>
							)}
						</div>
						{/* {teamNameList > 0 && ( */}
						<>
							{team === true && (
								<div className="flex w-full">
									{/* <Controller
											control={control}
											name="team"
											render={({ field }) => (
												<Select
													{...field}
													className="mb-24"
													variant="outlined"
													label="Select Team Name *"
													error={!!errors.team}
													required
													fullWidth
												>
													<MenuItem value="Select team Name" disabled>
														<em> Select Team Name * </em>
													</MenuItem>
													{teamNameList &&
														teamNameList.map(n => (
															<MenuItem value={n.s2_team.id} key={n.s2_team.id}>
																{n.s2_team.name}
															</MenuItem>
														))}
												</Select>
											)}
										/> */}
									<AsyncPaginate
										loadOptions={loadOptions}
										isMulti={false}
										selectRef={userIdRef}
										IndicatorSeparator={null}
										isClearable={false}
										closeMenuOnSelect={false}
										styles={colourStyles}
										onChange={event => {
											noRefCheck(event);
										}}
										className="relative invite_member inv-mem bg-white w-full mb-24 h-52"
										additional={{
											page: 0
										}}
									/>
								</div>
							)}
						</>
						{/* )} */}

						<div className="block relative">
							<Controller
								control={control}
								name="category"
								render={({ field }) => (
									<>
										<Select
											{...field}
											className="mb-24"
											variant="outlined"
											label="Select Bot Category *"
											error={!!errors.category}
											onClick={handleBotCategory}
											required
											fullWidth
										>
											<MenuItem value="Selectbot" disabled>
												<em> Select Bot Category * </em>
											</MenuItem>
											{botStoreCategoryTypeData &&
												botStoreCategoryTypeData.map(n => (
													<MenuItem value={n.id} key={n.id}>
														{n.category}
													</MenuItem>
												))}
										</Select>
										{noCategoryMsg && (
											<span className="text-red block absolute top-56">{noCategoryMsg}</span>
										)}
									</>
								)}
							/>
						</div>
						{/* <div className="flex">
							<Controller
								control={control}
								name="type"
								render={({ field }) => (
									<Select
										{...field}
										className="mb-24"
										variant="outlined"
										label="Select Type"
										onChange={e => {
											handleTypeDropdownChange(e);
										}}
										value={botType}
										error={!!errors.team}
										required
										fullWidth
									>
										<MenuItem value="1">Free</MenuItem>
										<MenuItem value="2">Paid</MenuItem>
									</Select>
								)}
							/>
						</div> */}
						{/* {botType === 2 && (
							<div className="flex flex-wrap mb-6">
								<div className="w-full px-3">
									<Controller
										name="bot_price"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-16"
												placeholder="Price"
												type="text"
												error={!!errors.bot_price}
												helperText={errors?.bot_price?.message}
												variant="outlined"
												fullWidth
											/>
										)}
									/>
								</div>
							</div>
						)} */}
						<div className="flex">
							<Controller
								control={control}
								name="description"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Description"
										id="description"
										multiline
										maxrows={4}
										error={!!errors.description}
										helperText={errors?.description?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
						</div>
						<div className="flex">
							<Controller
								control={control}
								name="shortDescription"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Short Description"
										multiline
										maxrows={2}
										id="shortDescription"
										error={!!errors.shortDescription}
										helperText={errors?.shortDescription?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
						</div>
						<div className="flex">
							<Controller
								control={control}
								name="password"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Password"
										id="password"
										type="password"
										error={!!errors.password}
										helperText={errors?.password?.message}
										variant="outlined"
										InputProps={{
											className: 'pr-2',
											type: showPassword ? 'text' : 'password',
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setShowPassword(!showPassword)}>
														<Icon className="text-20" color="action">
															{showPassword ? 'visibility_off' : 'visibility'}
														</Icon>
													</IconButton>
												</InputAdornment>
											)
										}}
										required
										fullWidth
									/>
								)}
							/>
						</div>
						<div className="flex">
							<Controller
								control={control}
								name="cnf_password"
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-24"
										label="Confirm password"
										id="cnf_password"
										type="password"
										error={!!errors.cnf_password}
										helperText={errors?.cnf_password?.message}
										variant="outlined"
										InputProps={{
											className: 'pr-2',
											type: showCnfPassword ? 'text' : 'password',
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={() => setShowCnfPassword(!showCnfPassword)}>
														<Icon className="text-20" color="action">
															{showCnfPassword ? 'visibility_off' : 'visibility'}
														</Icon>
													</IconButton>
												</InputAdornment>
											)
										}}
										required
										fullWidth
									/>
								)}
							/>
						</div>
					</DialogContent>
					<DialogActions className="p-4 pb-16 block px-24">
						<div className="flex justify-between items-center">
							<span>All * fields Required</span>
							<div>
								<Button
									className="rounded mr-12 bg-grey-300 w-96 hover:bg-black hover:text-white"
									variant="contained"
									type="button"
									onClick={handleRemove}
								>
									Cancel
								</Button>

								<Button
									className="bg-amber-500 w-96 hover:bg-black hover:text-white rounded"
									variant="contained"
									type="submit"
									disabled={
										_.isEmpty(dirtyFields) ||
										!isValid ||
										loading ||
										Boolean(errors?.name?.message) ||
										countryName.length === 0
									}
								>
									Create
								</Button>
								{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
							</div>
						</div>
					</DialogActions>
				</form>
			</Dialog>

			<Dialog
				open={approvalDialog}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						setApprovalDialog(false);
					}
				}}
				className="botdetailstoken"
				aria-labelledby="delete-app"
				aria-describedby="delete-app-description"
			>
				<DialogTitle id="alert-dialog-title" className="sm:min-w-sm text-center">
					<Icon color="primary" className="text-green-600 text-6xl">
						check_circle
					</Icon>
					<Typography className="text-green-600 text-24">Bot Added successfully!</Typography>
					{/* Bot Detail */}
				</DialogTitle>
				<DialogContent className="-mt-8 sm:min-w-sm">
					<DialogContentText id="alert-dialog-description">
						UserId:
						<Typography
							className="flex items-center justify-center border rounded-md px-8 py-4 mt-8 cursor-pointer botdetailtoken"
							onClick={copyToClipboardUserId}
						>
							<InputBase inputRef={userIdRef} value={approvalUserId || ''} fullWidth />
							<Button
								className="whitespace-nowrap rounded bg-amber-500 w-96 hover:bg-black hover:text-white"
								variant="contained"
								aria-label="Copy"
							>
								<Icon className="text-16 mr-5" title="copy UserId">
									content_copy
								</Icon>{' '}
								{copyUserIdSuccess}
							</Button>
						</Typography>
					</DialogContentText>
					<DialogContentText id="alert-dialog-description">
						Token:
						<Typography
							className="flex items-center justify-center border rounded-md px-8 py-4 mt-8 cursor-pointer botdetailtoken"
							onClick={copyToClipboardToken}
						>
							<InputBase inputRef={tokenRef} value={approvalToken || ''} fullWidth />
							<Button
								className="whitespace-nowrap rounded bg-amber-500 w-96 hover:bg-black hover:text-white"
								variant="contained"
								aria-label="Copy"
							>
								<Icon className="text-16 mr-5" title="copy UserId">
									content_copy
								</Icon>{' '}
								{copyTokenSuccess}
							</Button>
						</Typography>
					</DialogContentText>
					<DialogContentText id="alert-dialog-description">
						Bot Space RoomId:
						<Typography
							className="flex items-center justify-center border rounded-md px-8 py-4 mt-8 cursor-pointer botdetailtoken"
							onClick={copyToClipboardRoomId}
						>
							<InputBase inputRef={roomIdRef} value={botSpaceRoomId || ''} fullWidth />
							<Button
								className="whitespace-nowrap rounded bg-amber-500 w-96 hover:bg-black hover:text-white"
								variant="contained"
								aria-label="Copy"
							>
								<Icon className="text-16 mr-5" title="copy UserId">
									content_copy
								</Icon>{' '}
								{copyRoomIdSuccess}
							</Button>
						</Typography>
					</DialogContentText>
					<DialogContentText id="alert-dialog-description">
						Home Server Url:
						<Typography
							className="flex items-center justify-center border rounded-md px-8 py-4 mt-8 cursor-pointer botdetailtoken"
							onClick={copyToClipboardHomeServer}
						>
							<InputBase inputRef={homeServerRef} value={homeServerUrl || ''} fullWidth />
							<Button
								className="whitespace-nowrap rounded bg-amber-500 w-96 hover:bg-black hover:text-white"
								variant="contained"
								aria-label="Copy"
							>
								<Icon className="text-16 mr-5" title="copy UserId">
									content_copy
								</Icon>{' '}
								{copyHomeServerSuccess}
							</Button>
						</Typography>
					</DialogContentText>
				</DialogContent>
				<CSVLink data={csvData} filename="Bot-Detail" className="block text-center">
					<Button
						className="whitespace-nowrap rounded bg-amber-500 w-96 hover:bg-black hover:text-white"
						variant="contained"
					>
						<Icon className="text-16">download</Icon>
						<span className="sm:flex">&nbsp; Download</span>
					</Button>
				</CSVLink>

				<DialogActions>
					<Button onClick={() => setApprovalDialog(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
			{appDialogTeam?.props?.open && (
				<Dialog
					open={openTeamDialog}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							handleTeamCancel();
						}
					}}
					className="botdetailstoken"
					aria-labelledby="delete-app"
					aria-describedby="delete-app-description"
				>
					<DialogContent>
						<DialogContentText
							id="alert-dialog-description"
							className="text-15 text-black text-center"
							style={{ width: '300px' }}
						>
							You have not Created team yet, please create team first
						</DialogContentText>
					</DialogContent>

					<DialogActions className="text-center flex pb-20 px-20">
						<Button
							className="rounded py-8 text-center font-semibold w-96 bg-amber-500 w-96 hover:bg-black hover:text-white w-1/2"
							variant="contained"
							onClick={ev => dispatch(openNewTeamsDialog())}
						>
							<span className="sm:flex">Create Team</span>
						</Button>
						<Button
							onClick={() => handleTeamCancel()}
							className="rounded py-8 text-center font-semibold bg-grey-300 w-96 hover:bg-black hover:text-white w-1/2"
						>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<TeamsDialog />
		</div>
	);
}

export default withReducer('myBotsReducer', reducer)(MyBotsDialog);
