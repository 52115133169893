import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { useEffect, useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import ReactApexChart from 'react-apexcharts';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import history from '@history';
import Avatar from 'react-avatar';
import Query from 'app/main/query/user';
import stockData from 'app/main/query/stocks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ShareSocial } from 'react-share-social';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfluencerDialog from './InfluencerDialog';
import reducer from './store';
import userService from '../../../services/userService';
import MarketMoversDialog from './MarketMoversDialog';
import PerformersInfoDialog from './PerformersInfoDialog';
import { openLikesListDialog, openCommentDialog, openReportListDialog, resetcommon } from '../common/store/commonSlice';
import LikesListDialog from '../common/LikesListDialog';
import CommentDialog from '../common/CommentDialog';
import ReportListDialog from '../common/ReportListDialog';
import {
	resetDashboard,
	getStockDashboard,
	getDashboardCount,
	userFollowUnfollow,
	addStockWatchlist,
	removeWatchlistStock,
	openinfluencerDialog,
	openperformersDialog,
	openMarketMoversDialog,
	addUserBookmarkPost,
	addPostVote
} from './store/applicationDashboardSlice';

const useStyles = makeStyles(() => ({
	layoutRoot: {}
}));

function DashboardPage() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const notifyData = useSelector(({ applicationDashboardReducer }) =>
		applicationDashboardReducer?.dashboard?.notificationData?.data.length > 0
			? applicationDashboardReducer.dashboard.notificationData.data[0]
			: {}
	);
	const stockDashboardData = useSelector(({ applicationDashboardReducer }) =>
		applicationDashboardReducer?.applicationDashboard?.getStockDashboardData?.data?.getStockDashboard?.data
			?.length > 0
			? applicationDashboardReducer.applicationDashboard.getStockDashboardData.data.getStockDashboard.data[0]
					.response
			: []
	);

	const addWatchlistData = useSelector(
		({ applicationDashboardReducer }) =>
			applicationDashboardReducer?.applicationDashboard?.addStockWatchlistData?.data?.addStockWatchlist || ''
	);

	const removwWatchlistData = useSelector(
		({ applicationDashboardReducer }) =>
			applicationDashboardReducer?.applicationDashboard?.removeWatchlistStockData?.data?.removeWatchlistStock ||
			''
	);

	const getDashboardCountData = useSelector(
		({ applicationDashboardReducer }) =>
			applicationDashboardReducer?.applicationDashboard?.getDashboardCountData?.data?.getDashboardCount.data || ''
	);

	useSelector(({ applicationDashboardReducer }) =>
		applicationDashboardReducer?.applicationDashboard?.addPostVoteData?.data?.addPostVote
			? applicationDashboardReducer.applicationDashboard.addPostVoteData.data.addPostVote
			: {}
	);

	const likesListDialog = useSelector(({ commonReducer }) => commonReducer?.common?.likesListDialog);

	const commentDialog = useSelector(({ commonReducer }) => commonReducer?.common?.commentDialog);

	const reportListDialog = useSelector(({ commonReducer }) => commonReducer?.common?.reportListDialog);

	const influencerDialog = useSelector(
		({ applicationDashboardReducer }) => applicationDashboardReducer?.applicationDashboard?.influencerDialog
	);

	const performersDialog = useSelector(
		({ applicationDashboardReducer }) => applicationDashboardReducer?.applicationDashboard?.performersDialog
	);

	const marketMoversDialog = useSelector(
		({ applicationDashboardReducer }) => applicationDashboardReducer?.applicationDashboard?.marketMoversDialog
	);

	const bookmarkStatus = useSelector(({ applicationDashboardReducer }) => {
		return applicationDashboardReducer?.applicationDashboard?.addUserBookmarkPostData?.data?.addUserBookmarkPost
			? applicationDashboardReducer.applicationDashboard.addUserBookmarkPostData.data.addUserBookmarkPost
			: {};
	});

	const reportStatus = useSelector(({ commonReducer }) =>
		commonReducer?.common?.addPostUserReportTopicRecordData?.data?.addPostUserReportTopicRecord
			? commonReducer.common.addPostUserReportTopicRecordData.data.addPostUserReportTopicRecord
			: {}
	);

	let marketMoversIds = [];

	stockDashboardData.map(record => {
		if (record.type === 'mover_card') {
			if (record.list) {
				marketMoversIds = record.list.map(marketId => marketId.id);
			}
		}
		return true;
	});

	let influencerIds = [];

	stockDashboardData.map(record => {
		if (record.type === 'influencer_card') {
			if (record.list) {
				influencerIds = record.list.map(influencerId => influencerId.user_id);
			}
		}
		return true;
	});

	let performerIds = [];

	stockDashboardData.map(record => {
		if (record.type === 'top_performers_card') {
			if (record.list) {
				performerIds = record.list.map(performerId => performerId.user_id);
			}
		}
		return true;
	});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [followLoading, setFollowLoading] = useState(false);
	const loginUserData = userService.getUserData();
	const [iswatched, setIswatched] = useState();
	const [watchId, setWatchId] = useState();
	const loginUserId = parseInt(loginUserData.id, 10);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDialogFollow, setOpenDialogFollow] = useState(false);
	const [openDialogFollowTopPer, setOpenDialogFollowTopPer] = useState(false);
	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [postUrl, setPostUrl] = useState('');
	const [watchAll, setWatchAll] = useState();
	const [followAll, setFollowAll] = useState();
	const [postsData, setPostsData] = useState([]);
	const [postId, setPostId] = useState();
	const [openDialogWatchAll, setOpenDialogWatchAll] = useState(false);
	const [openDialogFollowAll, setOpenDialogFollowAll] = useState(false);
	const [openDialogPerformersFollowAll, setOpenDialogPerformersFollowAll] = useState(false);
	const [followUserId, setFollowUserId] = useState([]);
	const [followUserStatus, setFollowUserStatus] = useState(false);
	const [followUserName, setFollowUserName] = useState();

	useEffect(() => {
		let unmounted = false;

		const payload = {
			query: Query.getStockDashboard
		};
		dispatch(getStockDashboard(payload));
		if (!unmounted) {
			setTimeout(() => {
				setLoading(false);
			});
		}
		return () => {
			unmounted = true;
			dispatch(resetDashboard());
			dispatch(resetcommon());
		};
	}, [dispatch]);

	useEffect(() => {
		stockDashboardData.map(data => {
			if (data.type === 'post_card') {
				if (data.list && postsData.length === 0) {
					setPostsData(data.list);
				}
			}
			return true;
		});
	}, [postsData, stockDashboardData]);

	useEffect(() => {
		let unmounted = false;
		const payload = {
			query: Query.getDashboardCount
		};

		dispatch(getDashboardCount(payload));

		return () => {
			unmounted = true;
		};
	}, [dispatch]);

	useEffect(() => {
		if (notifyData && notifyData.message === 'fail') {
			enqueueSnackbar(notifyData.message, {
				variant: 'error',
				autoHideDuration: 2000
			});
		}
	}, [notifyData, enqueueSnackbar, dispatch]);

	useEffect(() => {
		if (addWatchlistData && addWatchlistData.status === 200) {
			setOpenDialog(false);
			enqueueSnackbar(addWatchlistData.message, {
				variant: 'success',
				autoHideDuration: 3000
			});
		}
		if (addWatchlistData && addWatchlistData.status === 400) {
			setOpenDialog(false);
			enqueueSnackbar(addWatchlistData.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
		}
	}, [addWatchlistData, enqueueSnackbar]);

	useEffect(() => {
		if (removwWatchlistData && removwWatchlistData.status === 200) {
			setOpenDialog(false);
			enqueueSnackbar(removwWatchlistData.message, {
				variant: 'success',
				autoHideDuration: 3000
			});
		}
		if (removwWatchlistData && removwWatchlistData.status === 400) {
			setOpenDialog(false);
			enqueueSnackbar(removwWatchlistData.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
		}
	}, [removwWatchlistData, enqueueSnackbar]);

	useEffect(() => {
		if (bookmarkStatus && bookmarkStatus.status === 200) {
			enqueueSnackbar(bookmarkStatus.message, {
				variant: 'success',
				autoHideDuration: 3000
			});
		}
		if (bookmarkStatus && bookmarkStatus.status === 400) {
			enqueueSnackbar(bookmarkStatus.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
		}
	}, [bookmarkStatus, enqueueSnackbar]);

	useEffect(() => {
		if (reportStatus && reportStatus.status === 200) {
			enqueueSnackbar('Post Reported succesfully', {
				variant: 'success',
				autoHideDuration: 3000
			});
		}
		if (reportStatus && reportStatus.status === 400) {
			enqueueSnackbar(reportStatus.message, {
				variant: 'error',
				autoHideDuration: 3000
			});
		}
	}, [reportStatus, enqueueSnackbar]);

	function handleFollowUnfollow(followStatus, userIds) {
		setFollowLoading(true);
		const userid = parseInt(userIds, 10);

		const payloadData = {
			query: Query.userFollowUnfollow,
			variables: { isFollow: !followStatus, userId: [userid] }
		};
		dispatch(userFollowUnfollow(payloadData));

		const payload = {
			query: Query.getStockDashboard
		};
		setTimeout(() => {
			dispatch(getStockDashboard(payload));
		}, 800);
		setOpenDialogFollowTopPer(false);
		setFollowLoading(false);
	}

	function handleFollowUnfollowInfluencer(followStatus, userIds) {
		setFollowLoading(userIds);
		const userid = parseInt(userIds, 10);

		const payloadData = {
			query: Query.userFollowUnfollow,
			variables: { isFollow: !followStatus, userId: [userid] }
		};
		dispatch(userFollowUnfollow(payloadData));

		setOpenDialogFollow(false);

		const payload = {
			query: Query.getStockDashboard
		};
		setTimeout(() => {
			dispatch(getStockDashboard(payload));
		}, 800);

		setFollowLoading(false);
	}

	function handleWatchlist() {
		setFollowLoading(watchId);
		const tickerid = parseInt(watchId, 10);
		if (iswatched === false) {
			const payloadData = {
				query: stockData.addStockWatchlist,
				variables: { tickerIds: [tickerid] }
			};
			dispatch(addStockWatchlist(payloadData));
		} else {
			const payloadData = {
				query: stockData.removeWatchlistStock,
				variables: { clearAll: false, tickerIds: [tickerid] }
			};
			dispatch(removeWatchlistStock(payloadData));
		}
		setOpenDialog(false);
		setTimeout(() => {
			const payload = {
				query: Query.getStockDashboard
			};
			dispatch(getStockDashboard(payload));
		}, 800);
		setFollowLoading(false);
	}

	function handleWatchlistAll() {
		const payloadData = {
			query: stockData.addStockWatchlist,
			variables: { tickerIds: watchAll }
		};
		dispatch(addStockWatchlist(payloadData));
		setOpenDialogWatchAll(false);

		const payload = {
			query: Query.getStockDashboard
		};
		setTimeout(() => {
			dispatch(getStockDashboard(payload));
		}, 800);
	}

	function handleFollowAll() {
		const payloadData = {
			query: Query.userFollowUnfollow,
			variables: { isFollow: true, userId: followAll }
		};
		dispatch(userFollowUnfollow(payloadData));

		setOpenDialogFollowAll(false);
		setOpenDialogPerformersFollowAll(false);

		const payload = {
			query: Query.getStockDashboard
		};
		setTimeout(() => {
			dispatch(getStockDashboard(payload));
		}, 800);
	}

	if (loading) {
		return <FuseLoading />;
	}
	function getChart(data, index) {
		const chart = {
			series: [
				{
					data,
					show: false
				}
			],

			options: {
				grid: {
					show: false,
					forceNiceScale: false
				},
				chart: {
					height: '100px',
					type: 'line',
					background: 'transparent',
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				stroke: {
					curve: 'smooth',
					width: [3, 3]
				},
				tooltip: {
					enabled: false
				},
				xaxis: {
					labels: {
						show: false
					},
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false
					}
				},
				yaxis: {
					labels: {
						show: false
					},
					axisBorder: {
						show: false
					}
				}
			}
		};

		return (
			<div id={`chart${index}`} style={{ height: '100px' }}>
				<ReactApexChart options={chart?.options} series={chart?.series} type="line" height={100} />
			</div>
		);
	}
	function calculateStock(currentPrice, previousPrice) {
		let def = 0;
		let Average = 0;
		let isLoss = true;
		const currentPriceValue = currentPrice ? Number(currentPrice).toFixed(0) : '0.00';
		const prevPrice = previousPrice ? Number(previousPrice).toFixed(0) : '0.00';

		if (currentPrice !== 0) {
			def = Number(currentPrice) - Number(previousPrice);
			Average =
				Number(currentPrice) === 0 ? Number(currentPrice).toFixed(2) : ((def * 100) / currentPrice).toFixed(2);

			isLoss = Average < 0;
		}

		const payload = {
			deference: Math.abs(def).toFixed(2),
			percentage: Number(Average).toFixed(2),
			isProfit: !isLoss,
			currentPriceValue,
			prevPrice,
			currency: '$',
			sign: isLoss ? '-' : '+',
			img: isLoss ? 'arrow_drop_down' : 'arrow_drop_up'
		};
		return (
			<div
				className={`inline text-12 ${
					payload.sign === '-' ? 'text-red-500' : 'text-green-500'
				} text-white truncate`}
			>
				{payload.sign} {payload.currency}
				{payload.deference}
				<Icon className="align-middle">{payload.img}</Icon>
			</div>
		);
	}

	function redirectTradingRoutes(id) {
		return history.push({
			pathname: `./portfolio/tradingview/${id}`
		});
	}

	function redirectProfileRoutes(id) {
		return history.push({
			pathname: `../user/${id}`
		});
	}

	function renderDescription(description, mentionuserlist, mentionstocklist) {
		const descriptionArr = description.length > 0 ? description.split(' ') : [];
		return (
			<Typography className="md:text-14 block leading-5 break-word mb-16" component="span">
				{descriptionArr &&
					descriptionArr.slice(0).map((i, ind) => {
						if (i[0] === '@') {
							if (mentionuserlist) {
								let mentionedUser = [];

								const id = i[0] === '@' && i.replace('@stockly.', '').replace('.', '');
								mentionedUser =
									mentionuserlist && mentionuserlist.find(ii => ii.id === parseInt(id, 10) && ii);
								if (mentionedUser !== undefined) {
									return (
										<Typography
											key={ind}
											onClick={() => redirectProfileRoutes(mentionedUser?.id)}
											className="text-yellow-800 font-semibold inline-block cursor-pointer"
										>
											{`${i[0]}${mentionedUser?.user_name}`} &nbsp;{' '}
										</Typography>
									);
								}
								return description;
							}
						} else if (i[0] === '$') {
							if (mentionstocklist) {
								let mentionStock = [];

								const id = i[0] === '$' && i.replace('$stockly.', '').replace('.', '');
								mentionStock =
									mentionstocklist && mentionstocklist.find(j => j?.id === parseInt(id, 10) && j);
								if (mentionStock !== undefined) {
									return (
										<Typography
											key={ind}
											onClick={() => redirectTradingRoutes(mentionStock?.ticker_id)}
											className="text-yellow-800 font-semibold inline-block cursor-pointer"
										>
											{`${i[0]}${mentionStock?.ticker_symbol}`} &nbsp;
										</Typography>
									);
								}
							}
							return description;
						} else {
							return `${i} `;
						}
						return true;
					})}
				{/* <Typography>{!textShown && descriptionData?.length >= 170 && t('readmore')}</Typography> */}
			</Typography>
		);
	}

	function LinearProgressWithLabel(props) {
		return <LinearProgress variant="determinate" {...props} className="custom_progressbar rounded-lg" />;
	}

	function handlePostVote(downVote, upVote, postIds) {
		const postCurrentId = parseInt(postIds, 10);
		const payloadData = {
			query: Query.addPostVote,
			variables: { isDownVote: downVote, isUpVote: !upVote, postId: postCurrentId }
		};

		const tempData = JSON.parse(JSON.stringify(postsData));
		tempData.map((element, i) => {
			if (element.id === postIds) {
				const newData = JSON.parse(JSON.stringify(element));
				if (upVote) {
					newData.is_user_up_vote = false;
					// newData.is_user_down_vote = false;
					newData.up_vote -= 1;
					// if (!element.is_user_up_vote && !element.is_user_down_vote) {
					// 	newData.down_vote = element.down_vote;
					// } else {
					// 	newData.down_vote = element.down_vote !== 0 ? newData.down_vote - 1 : 0;
					// }
				} else {
					newData.is_user_up_vote = true;
					// newData.is_user_down_vote = false;
					newData.up_vote += 1;
					// if (!element.is_user_up_vote && !element.is_user_down_vote) {
					// 	newData.down_vote = element.down_vote;
					// } else {
					// 	newData.down_vote =
					// 		element.down_vote !== 0 ? newData.down_vote - 1 : 0;
					// }
				}
				tempData[i] = newData;
			}

			return true;
		});
		setPostsData(tempData);
		dispatch(addPostVote(payloadData));
	}
	function handleBookmark(bookmark, bookmarkPostId) {
		const payloadData = {
			query: Query.addUserBookmarkPost,
			variables: { is_bookmark: !bookmark, postId: bookmarkPostId }
		};

		const tempData = JSON.parse(JSON.stringify(postsData));
		tempData.map((element, i) => {
			if (element.id === bookmarkPostId) {
				const newData = JSON.parse(JSON.stringify(element));
				newData.is_bookmark = !bookmark;
				tempData[i] = newData;
			}
			return true;
		});
		setPostsData(tempData);
		dispatch(addUserBookmarkPost(payloadData));
	}
	if (loading) {
		return <FuseLoading />;
	}

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			content={
				<div className="p-24">
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0 pb-32 text-center px-16">
						<motion.div className="widget flex flex-col flex-auto w-full py-16">
							<Typography
								variant="subtitle1"
								className="w-full max-w-320 shadow bg-white rounded-lg p-12 gap-24 flex items-center justify-between"
								component="div"
							>
								<div className="flex items-center justify-center">
									<Link
										to="/application-user/portfolio/watch-list"
										className="items-center cursor-pointer"
									>
										<img
											className="max-w-52 max-h-52 mx-auto inline-block"
											src="assets/images/apllication/watch.png"
											alt="logo"
										/>

										<div className="ml-16 items-center inline-block">
											<Typography
												variant="subtitle1"
												className="font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
											>
												Watch List
											</Typography>
										</div>
									</Link>
								</div>
								<div className="text-3xl sm:text-2xl font-bold tracking-tight leading-none text-indigo-500">
									{getDashboardCountData?.watchlistCount}
								</div>
							</Typography>
						</motion.div>

						<motion.div className="widget flex flex-col flex-auto w-full py-16">
							<Typography
								variant="subtitle1"
								className="w-full max-w-320 shadow bg-white rounded-lg p-12 gap-24 flex items-center justify-between"
								component="div"
							>
								<div className="flex items-center">
									<Link to="/application-user/portfolio/portfolio-list" className="items-center">
										<img
											className="max-w-52 max-h-52 mx-auto inline-block"
											src="assets/images/apllication/porfolio.png"
											alt="logo"
										/>

										<div className="ml-16 items-center justify-center inline-block">
											<Typography
												variant="subtitle1"
												className="font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
											>
												Portfolio List
											</Typography>
										</div>
									</Link>
								</div>
								<div className="text-3xl sm:text-2xl font-bold tracking-tight leading-none text-indigo-500">
									{getDashboardCountData?.portfolioCount}
								</div>
							</Typography>
						</motion.div>

						<motion.div className="widget flex flex-col flex-auto w-full py-16">
							<Typography
								variant="subtitle1"
								className="w-full max-w-320 shadow bg-white rounded-lg p-12 gap-24 flex items-center justify-between"
								component="div"
							>
								<div className="flex items-center">
									<Link to="/application-user/portfolio/alerts" className="items-center">
										<img
											className="max-w-52 max-h-52 mx-auto inline-block"
											src="assets/images/apllication/alerts.png"
											alt="logo"
										/>
										<div className="ml-16 items-center justify-center inline-block">
											<Typography
												variant="subtitle1"
												className="font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
											>
												Alerts
											</Typography>
										</div>
									</Link>
								</div>

								<div className="text-3xl sm:text-2xl font-bold tracking-tight leading-none text-indigo-500">
									{getDashboardCountData?.alertCount}
								</div>
							</Typography>
						</motion.div>

						<motion.div className="widget flex flex-col flex-auto w-full py-16">
							<Typography
								variant="subtitle1"
								className="w-full max-w-320 shadow bg-white rounded-lg p-12 gap-24 flex items-center justify-between"
								component="div"
							>
								<div className="flex items-center justify-center">
									<Link to={`/user/${loginUserId}`} className="items-center">
										<img
											className="max-w-52 max-h-52 mx-auto inline-block"
											src="assets/images/apllication/post.png"
											alt="logo"
										/>

										<div className="ml-16 items-center inline-block">
											<Typography
												variant="subtitle1"
												className="font-semibold text-sm text-center text-black md:tracking-wide cursor-pointer"
											>
												Post List
											</Typography>
										</div>
									</Link>
								</div>
								<div className="text-3xl sm:text-2xl font-bold tracking-tight leading-none text-indigo-500">
									{getDashboardCountData?.postCount}
								</div>
							</Typography>
						</motion.div>
					</div>
					<div className="block pb-32">
						{stockDashboardData &&
							stockDashboardData.length > 0 &&
							stockDashboardData.map((response, index) => (
								<div key={index}>
									{response.type === 'top_performers_card' && response.list.length > 0 && (
										<div>
											<div className="flex items-center justify-between mb-16">
												<Typography
													component={motion.span}
													initial={{ x: -20 }}
													animate={{ x: 0, transition: { delay: 0.2 } }}
													delay={300}
													className="sm:flex text-14 md:text-20 font-semibold items-center"
												>
													Top Performers
													{response?.info && (
														<Tooltip
															TransitionComponent={Fade}
															arrow
															title={<h6 className="text-11">{response?.info}</h6>}
														>
															<Icon className="ml-5 text-blue text-16">info</Icon>
														</Tooltip>
													)}
												</Typography>
												<div className="flex items-center">
													<Typography
														component={motion.span}
														initial={{ x: -20 }}
														animate={{ x: 0, transition: { delay: 0.2 } }}
														delay={300}
														onClick={() => {
															dispatch(openperformersDialog());
														}}
														className="flex text-14 md:text-14 text-indigo font-semibold border-b border-indigo mr-24 h-20 cursor-pointer"
													>
														See All
													</Typography>
													<button
														className="whitespace-nowrap rounded border-1 border-indigo-500 px-16 py-8 text-center bg-white-500 text-indigo font-semibold"
														color="primary"
														type="submit"
														onClick={() => {
															setOpenDialogPerformersFollowAll(true);
															setFollowAll(performerIds);
														}}
														aria-label="Following"
													>
														Follow All
													</button>
												</div>
											</div>
											<div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-16 pb-32 text-center">
												{response &&
													response.list &&
													response.list.length > 0 &&
													response.list.map((topPerformers, i) => (
														<motion.div
															className="widget w-full flex flex-col flex-auto bg-white rounded-lg p-8 lg:p-12 shadow"
															key={i}
														>
															<div className="items-center text-center">
																<Typography
																	onClick={() =>
																		redirectProfileRoutes(topPerformers.user_id)
																	}
																	className="w-72 block h-72 mx-auto  p-5 border-grey-300 border-1 rounded-full"
																>
																	{/* <img
                                                                        className="cursor-pointer img-thumbnail mx-auto w-72 h-72 p-5 rounded-full border-grey-300 border-1 mx-auto justify-center"
                                                                        src={
                                                                            topPerformers.user_img
                                                                                ? topPerformers.user_img
                                                                                : 'assets/images/avatars/default_user.png'
                                                                        }
                                                                        alt="Apps"
                                                                    /> */}
																	<Avatar
																		name={
																			topPerformers.full_name
																				? topPerformers.full_name
																				: topPerformers.username
																		}
																		className="cursor-pointer mx-auto capitalize"
																		size="60px"
																		alt="Apps"
																		round
																		src={topPerformers?.user_img}
																		maxInitials={2}
																	/>
																</Typography>
																<Typography
																	variant="subtitle1"
																	className="text-center cursor-pointer mt-16"
																	onClick={() =>
																		redirectProfileRoutes(topPerformers.user_id)
																	}
																	component="span"
																>
																	<span className="text-ellipsis overflow-hidden block MuiTypography-root-190 font-semibold text-sm text-black md:tracking-wide MuiTypography-subtitle1-201 h-20  whitespace-nowrap overflow-hidden text-ellipsis capitalize">
																		{topPerformers.full_name
																			? topPerformers.full_name
																			: ''}
																	</span>
																	<span className="block MuiTypography-root-190 text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																		{topPerformers.user_name
																			? `@${topPerformers.user_name}`
																			: ''}
																	</span>
																</Typography>
															</div>
															{topPerformers?.chart_data ? (
																<div className="container relative pb-16">
																	{getChart(topPerformers?.chart_data, index)
																		? getChart(topPerformers?.chart_data, index)
																		: ''}
																</div>
															) : (
																<div
																	className="container relative pb-16 block"
																	style={{ height: '117px' }}
																>
																	{' '}
																	&nbsp;{' '}
																</div>
															)}
															<div className="block text-center text-14 text-green-500 mb-16">
																{topPerformers?.p_change}%
																<Icon className="align-middle ml-6">arrow_drop_up</Icon>
															</div>

															<div className="items-center text-center mb-16 grid-cols-2 grid">
																<div className="text-center border-dashed border-r-1 ">
																	<span className=" block MuiTypography-root-190 text-black font-semibold lg:text-20 text-16 md:tracking-wide MuiTypography-subtitle1-201">
																		{topPerformers?.num_followers
																			? topPerformers?.num_followers
																			: '0'}
																	</span>
																	<span className="block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																		Followers
																	</span>
																</div>
																<div className="text-center">
																	<span className="block MuiTypography-root-190 text-black font-semibold lg:text-20 text-16 md:tracking-wide MuiTypography-subtitle1-201">
																		{topPerformers.num_posts
																			? topPerformers.num_posts
																			: '0'}
																	</span>
																	<span className="block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																		Posts
																	</span>
																</div>
															</div>
															{parseInt(topPerformers.user_id, 10) !==
																parseInt(loginUserData.id, 10) && (
																<div className="block w-full">
																	<button
																		className={`whitespace-nowrap rounded  border-1 border-indigo-500 py-8 text-center block w-full ${
																			topPerformers.is_follow
																				? 'bg-white-500 text-indigo'
																				: 'bg-indigo-500 text-white'
																		}`}
																		color="primary"
																		type="button"
																		id={i}
																		onClick={
																			topPerformers?.is_follow
																				? () => {
																						setOpenDialogFollowTopPer(true);
																						setFollowUserId(
																							topPerformers.user_id
																						);
																						setFollowUserStatus(
																							topPerformers.is_follow
																						);
																						setFollowUserName(
																							topPerformers.user_name
																						);
																				  }
																				: () => {
																						handleFollowUnfollow(
																							topPerformers.is_follow,
																							topPerformers.user_id
																						);
																				  }
																		}
																		aria-label="Following"
																	>
																		{followLoading &&
																		followLoading === topPerformers.user_id ? (
																			<CircularProgress
																				size={10}
																				disableShrink
																				style={{ color: 'indigo' }}
																			/>
																		) : (
																			<span className="block font-semibold">
																				{topPerformers.is_follow
																					? 'Following'
																					: 'Follow'}
																			</span>
																		)}
																	</button>
																</div>
															)}
														</motion.div>
													))}
											</div>
										</div>
									)}
								</div>
							))}

						{stockDashboardData &&
							stockDashboardData.length > 0 &&
							stockDashboardData.map((response, index) => (
								<div key={index}>
									{/* <div className="block">{response.type}</div> */}
									{response.type === 'influencer_card' && response.list.length > 0 && (
										<div>
											<div className="flex items-center justify-between mb-16">
												<Typography
													component={motion.span}
													initial={{ x: -20 }}
													animate={{ x: 0, transition: { delay: 0.2 } }}
													delay={300}
													className="sm:flex text-14 md:text-20 font-semibold items-center"
												>
													Top Influencers
													{response?.info && (
														<Tooltip
															className=""
															TransitionComponent={Fade}
															arrow
															title={<h6 className="text-11">{response?.info}</h6>}
														>
															<Icon className="ml-5 text-blue text-16">info</Icon>
														</Tooltip>
													)}
												</Typography>
												<div className="flex items-center">
													<Typography
														component={motion.span}
														initial={{ x: -20 }}
														animate={{ x: 0, transition: { delay: 0.2 } }}
														delay={300}
														onClick={() => {
															dispatch(openinfluencerDialog());
														}}
														className="flex text-14 md:text-14 text-indigo font-semibold border-b border-indigo mr-24 h-20 cursor-pointer"
													>
														See All
													</Typography>
													<button
														className="whitespace-nowrap rounded border-1 border-indigo-500 px-16 py-8 text-center bg-white-500 text-indigo font-semibold"
														color="primary"
														type="submit"
														onClick={() => {
															setOpenDialogFollowAll(true);
															setFollowAll(influencerIds);
														}}
														aria-label="Following"
													>
														Follow All
													</button>
												</div>
											</div>
											<div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-16 pb-32 text-center mt-40">
												{response &&
													response.list &&
													response.list.length > 0 &&
													response.list.slice(0, 5).map((influencerData, i) => (
														<motion.div
															className="widget w-full flex flex-col flex-auto bg-white rounded-lg p-8 lg:p-12 shadow mt-28 sm:mt-0"
															key={i}
														>
															<div
																className="block items-center justify-between text-center"
																key={i}
															>
																<Typography
																	onClick={() =>
																		redirectProfileRoutes(influencerData.user_id)
																	}
																	component="span"
																>
																	{/* <img
                                                                        className="-mt-40 block w-52 h-52 rounded-full border-1 mx-auto justify-center mb-10  "
                                                                        src={
                                                                            influencerData.user_img
                                                                                ? influencerData.user_img
                                                                                : 'assets/images/avatars/default_user.png'
                                                                        }
                                                                        alt="Market_image"
                                                                        onError={e => {
                                                                            e.currentTarget.src =
                                                                                'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png';
                                                                        }}
                                                                    /> */}
																	<Avatar
																		name={
																			influencerData.full_name
																				? influencerData.full_name
																				: influencerData.username
																		}
																		className="cursor-pointer mx-auto -mt-20 block mb-10 capitalize"
																		size="52px"
																		alt="Apps"
																		round
																		src={influencerData?.user_img}
																		maxInitials={2}
																	/>
																</Typography>
																<Typography
																	variant="subtitle1"
																	className="w-full text-center cursor-pointer"
																	onClick={() =>
																		redirectProfileRoutes(influencerData.user_id)
																	}
																	component="span"
																>
																	<span className="block capitalize MuiTypography-root-190 font-semibold text-sm text-black lg:tracking-wide MuiTypography-subtitle1-201 h-20 whitespace-nowrap overflow-hidden text-ellipsis">
																		{influencerData.full_name
																			? influencerData.full_name
																			: ''}
																	</span>
																	<span className="mb-12 block MuiTypography-root-190 text-sm text-grey lg:tracking-wide MuiTypography-subtitle1-201">
																		{influencerData.user_name
																			? `@${influencerData.user_name}`
																			: ''}
																	</span>
																</Typography>
															</div>
															<div className="flex items-center justify-between text-left mb-12">
																<div className="text-left">
																	<span className="block MuiTypography-root-190 font-semibold text-xs lg:text-sm text-grey lg:tracking-wide MuiTypography-subtitle1-201">
																		{influencerData.num_followers
																			? influencerData.num_followers
																			: '0'}
																		&nbsp; Followers
																	</span>
																</div>
																{influencerData.num_posts > 0 && (
																	<div className="text-left">
																		<span className="block MuiTypography-root-190 font-semibold text-xs lg:text-sm text-grey lg:tracking-wide MuiTypography-subtitle1-201">
																			{influencerData.num_posts
																				? influencerData.num_posts
																				: ''}
																			&nbsp; Posts
																		</span>
																	</div>
																)}
															</div>
															<div className="container relative pb-16">
																<span className="block MuiTypography-root-190 text-indigo-500 font-semibold lg:text-20 text-16 md:tracking-wide MuiTypography-subtitle1-201">
																	{influencerData.s_score
																		? influencerData.s_score
																		: '0'}
																</span>
																<span className="block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																	Stockly Score
																</span>
															</div>
															{parseInt(influencerData.user_id, 10) !==
																parseInt(loginUserData.id, 10) && (
																<div className="block w-full">
																	<button
																		className={`whitespace-nowrap rounded border-1 border-indigo-500 px-16 py-8 text-center ${
																			influencerData.is_follow
																				? 'bg-white-500 text-indigo'
																				: 'bg-indigo-500 text-white'
																		}`}
																		color="primary"
																		type="submit"
																		aria-label="Following"
																		onClick={
																			influencerData?.is_follow
																				? () => {
																						setOpenDialogFollow(true);
																						setFollowUserId(
																							influencerData.user_id
																						);
																						setFollowUserStatus(
																							influencerData.is_follow
																						);
																						setFollowUserName(
																							influencerData.user_name
																						);
																				  }
																				: () => {
																						handleFollowUnfollowInfluencer(
																							influencerData.is_follow,
																							influencerData.user_id
																						);
																				  }
																		}
																	>
																		{followLoading &&
																		followLoading === influencerData.user_id ? (
																			<CircularProgress
																				size={10}
																				disableShrink
																				style={{ color: 'white' }}
																			/>
																		) : (
																			<span className="block font-semibold">
																				{influencerData.is_follow
																					? 'Following'
																					: 'Follow'}
																			</span>
																		)}
																	</button>
																</div>
															)}
														</motion.div>
													))}
											</div>
										</div>
									)}
								</div>
							))}

						{stockDashboardData &&
							stockDashboardData.length > 0 &&
							stockDashboardData.map((response, index) => (
								<div key={index}>
									{response.type === 'mover_card' && response.list.length > 0 && (
										<div>
											<div className="flex items-center justify-between mb-16">
												<Typography
													component={motion.span}
													initial={{ x: -20 }}
													animate={{ x: 0, transition: { delay: 0.2 } }}
													delay={300}
													className="text-14 md:text-20 font-semibold items-center"
												>
													Market Movers
													{response?.info && (
														<Tooltip
															TransitionComponent={Fade}
															arrow
															title={<h6 className="text-11">{response?.info}</h6>}
														>
															<Icon className="ml-5 text-blue text-16">info</Icon>
														</Tooltip>
													)}
												</Typography>
												<div className="flex items-center">
													<Typography
														component={motion.span}
														initial={{ x: -20 }}
														animate={{ x: 0, transition: { delay: 0.2 } }}
														delay={300}
														onClick={() => {
															dispatch(openMarketMoversDialog());
														}}
														className="flex text-14 md:text-14 text-indigo font-semibold border-b border-indigo mr-24 h-20 cursor-pointer"
													>
														See All
													</Typography>
													<button
														className="whitespace-nowrap rounded border-1 border-indigo-500 px-16 py-8 text-center bg-white-500 text-indigo font-semibold"
														color="primary"
														type="submit"
														onClick={() => {
															setOpenDialogWatchAll(true);
															setWatchAll(marketMoversIds);
														}}
														aria-label="Following"
													>
														Watch All
													</button>
												</div>
											</div>
											<div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-16 pb-32 text-center">
												{response &&
													response.list &&
													response.list.length > 0 &&
													response.list.map((marketData, i) => (
														<motion.div
															className="widget w-full flex flex-col flex-auto bg-white rounded-lg p-8 lg:p-12 shadow"
															key={i}
														>
															<div className="block items-center justify-between text-center">
																<Typography
																	onClick={() =>
																		redirectTradingRoutes(marketData?.ticker_id)
																	}
																>
																	<img
																		className="block w-72 rounded-full h-72 p-5 rounded-full border-grey-300 border-1 mx-auto justify-center mb-10 cursor-pointer"
																		src={
																			marketData.ticker_img
																				? marketData.ticker_img
																				: 'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png'
																		}
																		alt="Market_image"
																		onError={e => {
																			e.currentTarget.src =
																				'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png';
																		}}
																	/>
																</Typography>
																<Typography
																	variant="subtitle1"
																	className="w-full text-center cursor-pointer"
																	onClick={() =>
																		redirectTradingRoutes(marketData?.ticker_id)
																	}
																	component="span"
																>
																	<span className="text-ellipsis overflow-hidden text-ellipsis overflow-hidden block MuiTypography-root-190 font-semibold text-sm text-black md:tracking-wide MuiTypography-subtitle1-201 h-20  whitespace-nowrap overflow-hidden text-ellipsis">
																		{marketData.ticker_name
																			? marketData.ticker_name
																			: ''}
																	</span>
																	<span className="block MuiTypography-root-190 text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																		{marketData.ticker_id
																			? marketData.ticker_id
																			: ''}
																	</span>
																</Typography>
															</div>
															{marketData?.chart_data && (
																<div className="container relative pb-16">
																	{getChart(marketData?.chart_data, index)
																		? getChart(marketData?.chart_data, index)
																		: ''}
																</div>
															)}
															<div className="flex items-center justify-between text-left mb-12">
																<div className="text-left">
																	<span className="block MuiTypography-root-190 text-black font-semibold text-20 md:tracking-wide MuiTypography-subtitle1-201">
																		{calculateStock(
																			marketData.ticker_price,
																			marketData.ticker_prev_close_price
																		)
																			? calculateStock(
																					marketData.ticker_price,
																					marketData.ticker_prev_close_price
																			  )
																			: '0'}
																	</span>
																	<span className="hidden lg:block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																		Exchange Rate
																	</span>
																</div>
																<div className="text-left">
																	<span className="block MuiTypography-root-190 text-black font-semibold lg:text-20 text-16 md:tracking-wide MuiTypography-subtitle1-201">
																		{marketData.ticker_price
																			? `${
																					loginUserData?.s2_countries
																						?.currency === 'INR'
																						? '₹'
																						: '$'
																			  }${marketData.ticker_price.toFixed(2)}`
																			: ''}
																	</span>
																	<span className="hidden lg:block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
																		price
																	</span>
																</div>
															</div>
															<div className="block w-full">
																<button
																	className={`whitespace-nowrap rounded border-1 border-indigo-500 px-16 py-8 text-center ${
																		marketData.is_watched
																			? 'bg-white-500 text-indigo'
																			: 'bg-indigo-500 text-white'
																	}`}
																	color="primary"
																	type="submit"
																	onClick={() => {
																		setOpenDialog(true);
																		setIswatched(marketData.is_watched);
																		setWatchId(marketData.id);
																	}}
																	aria-label="Following"
																>
																	{followLoading &&
																	followLoading === marketData.id ? (
																		<CircularProgress
																			size={10}
																			disableShrink
																			style={{ color: 'white' }}
																		/>
																	) : (
																		<span className="block font-semibold">
																			{marketData.is_watched
																				? 'Watching'
																				: 'Watch'}
																		</span>
																	)}
																</button>
															</div>
														</motion.div>
													))}
											</div>
										</div>
									)}
								</div>
							))}

						<div>
							{stockDashboardData.length > 0 && (
								<div className="block items-center mb-16">
									<Typography
										component={motion.span}
										initial={{ x: -20 }}
										animate={{ x: 0, transition: { delay: 0.2 } }}
										delay={300}
										className="flex text-14 md:text-20 font-semibold  mt-32"
									>
										Related Posts
									</Typography>
								</div>
							)}
							{stockDashboardData &&
								stockDashboardData.length > 0 &&
								stockDashboardData.map((response, indexPost) => (
									<div
										className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-16 text-left max-w-md"
										key={indexPost}
									>
										{response.type === 'post_card' && (
											<>
												{postsData &&
													postsData.length > 0 &&
													postsData.map((data, index) => (
														<div className="bg-white rounded-lg mb-24 border" key={index}>
															<div className="flex items-center mx-0 ng-star-inserted justify-between border-b p-9">
																<div className="flex">
																	<Avatar
																		name={
																			data?.full_name
																				? data?.full_name
																				: data?.user_name
																		}
																		className="mr-9 cursor-pointer capitalize"
																		onClick={() =>
																			redirectProfileRoutes(data?.user_id)
																		}
																		size="36px"
																		alt="profile_image"
																		maxInitials={2}
																		round
																		src={data?.user_image}
																	/>
																	<Typography
																		onClick={() =>
																			redirectProfileRoutes(data?.user_id)
																		}
																		className="cursor-pointer"
																	>
																		<span className="block leading-tight">
																			<span className="font-semibold md:text-14 leading-none capitalize">
																				{data?.full_name}
																			</span>
																		</span>
																		<span className="block leading-tight">
																			<span className="text-grey-500">
																				{data?.user_name
																					? `@${data?.user_name}`
																					: ''}
																			</span>
																		</span>
																	</Typography>
																</div>
																{loginUserData?.id !== data?.user_id && (
																	<span
																		className="text-grey-500 block text-12 flex justify-between items-center cursor-pointer"
																		onClick={() => {
																			dispatch(openReportListDialog());
																			setPostId(data?.id);
																		}}
																		aria-hidden="true"
																	>
																		<Icon color="action">more_horiz</Icon>
																	</span>
																)}
															</div>
															<div className="p-12">
																{data?.description && (
																	<>
																		{renderDescription(
																			data.description,
																			data.mention_user_list,
																			data.mention_stock_list
																		)}
																	</>
																)}

																{data?.news_data.news_content && (
																	<div className="border border-grey-300 p-16 mt-24 rounded-md">
																		{data.news_data.ticker_details && (
																			<Swiper
																				breakpoints={{
																					319: {
																						slidesPerView: 1
																					},
																					640: {
																						slidesPerView: 2
																					},
																					768: {
																						slidesPerView: 3
																					},
																					992: {
																						slidesPerView: 4
																					}
																				}}
																				slidesPerView={4}
																				spaceBetween={10}
																				pagination={{
																					clickable: true
																				}}
																				modules={[Pagination]}
																				className="mySwiper"
																			>
																				{data &&
																					data.news_data.ticker_details &&
																					data.news_data.ticker_details
																						.length > 0 &&
																					data.news_data.ticker_details.map(
																						(newsData, newsIndex) => (
																							<SwiperSlide
																								key={newsIndex}
																							>
																								<div className="block cursor-pointer">
																									<div className="flex">
																										<img
																											onClick={() =>
																												redirectTradingRoutes(
																													newsData?.ticker_id
																												)
																											}
																											aria-hidden="true"
																											src={
																												newsData?.ticker_image
																													? newsData.ticker_image
																													: 'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png'
																											}
																											onError={e => {
																												e.currentTarget.src =
																													'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png';
																											}}
																											alt="ticker_image"
																											className="w-36 h-36 rounded-full mr-12"
																										/>
																										<div
																											onClick={() =>
																												redirectTradingRoutes(
																													newsData?.ticker_id
																												)
																											}
																											aria-hidden="true"
																										>
																											<div className="mb-3">
																												<span className="font-semibold md:text-14 leading-none">
																													{
																														newsData?.ticker_symbol
																													}
																												</span>
																											</div>
																											<div>
																												<span className="text-grey-500 whitespace-nowrap w-96 inline-block text-ellipsis overflow-hidden">
																													{
																														newsData?.ticker_name
																													}
																												</span>
																											</div>
																										</div>
																									</div>
																								</div>
																							</SwiperSlide>
																						)
																					)}
																			</Swiper>
																		)}
																		<h3 className="font-semibold text-14 mb-6 mt-10">
																			{data?.news_data?.news_title}
																		</h3>
																		<p className="text-grey-500 leading-5 mb-16 break-word">
																			{data?.news_data?.news_content}
																		</p>
																		{data?.news_data?.news_image && (
																			<img
																				src={
																					data?.news_data?.news_image
																						? data.news_data.news_image
																						: 'assets/images/avatars/default_user.png'
																				}
																				alt="news_image"
																				onError={e => {
																					e.currentTarget.src =
																						'https://stockly-public.s3.amazonaws.com/default-image/default-stock-2.png';
																				}}
																				className="w-6/12 rounded-lg"
																			/>
																		)}
																	</div>
																)}
																{data?.post_type === 'image' && (
																	<Swiper
																		breakpoints={{
																			319: {
																				slidesPerView: 1
																			},
																			599: {
																				slidesPerView: 1
																			},
																			767: {
																				slidesPerView: 1
																			},
																			992: {
																				slidesPerView: 1
																			},
																			1200: {
																				slidesPerView: 1
																			}
																		}}
																		slidesPerView={1}
																		spaceBetween={10}
																		pagination={{
																			clickable: true
																		}}
																		modules={[Pagination]}
																		className="mySwiper"
																	>
																		{data &&
																			data.attachment_data &&
																			data.attachment_data.length > 0 &&
																			data.attachment_data.map(
																				(attachmentData, attachmentindex) => (
																					<SwiperSlide
																						className=""
																						key={attachmentindex}
																					>
																						<img
																							className="rounded-lg"
																							src={
																								attachmentData?.file_url
																									? attachmentData.file_url
																									: 'assets/images/avatars/default_user.png'
																							}
																							alt="Post_img"
																						/>
																					</SwiperSlide>
																				)
																			)}
																	</Swiper>
																)}
																{data?.post_type === 'poll' && (
																	<>
																		{data &&
																			data.post_poll_data &&
																			data.post_poll_data.length > 0 &&
																			data.post_poll_data.map((pollData, i) => (
																				<div
																					className="border border-grey-200 mt-24 p-16 rounded-sm max-w-sm"
																					key={i}
																				>
																					<div className="font-semibold bg-grey-300 px-12 py-6 rounded-sm">
																						{pollData?.question}
																					</div>
																					{pollData &&
																						pollData.options &&
																						pollData.options.length > 0 &&
																						pollData.options.map(
																							(
																								pollOptionData,
																								optioni
																							) => (
																								<div key={optioni}>
																									<div className="mt-24 flex justify-between pb-6">
																										<span>
																											{
																												pollOptionData?.option
																											}
																										</span>
																										<span>
																											{pollData?.total_user_votes >
																											0
																												? parseFloat(
																														(
																															((pollOptionData?.result
																																? pollOptionData?.result
																																: 0) /
																																(pollData?.total_user_votes
																																	? pollData?.total_user_votes
																																	: 0)) *
																															100
																														).toFixed(
																															2
																														)
																												  )
																												: '0'}
																											%
																										</span>
																									</div>
																									<LinearProgressWithLabel
																										className="rounded-lg h-8"
																										// value={pollOptionData?.result}
																										value={
																											parseInt(
																												pollData?.total_user_votes,
																												10
																											) > 0
																												? parseFloat(
																														(
																															((pollOptionData?.result
																																? pollOptionData?.result
																																: 0) /
																																(pollData?.total_user_votes
																																	? pollData?.total_user_votes
																																	: 0)) *
																															100
																														).toFixed(
																															2
																														)
																												  )
																												: '0'
																										}
																									/>
																								</div>
																							)
																						)}
																					<div className="mt-16">
																						<span className="font-semibold">
																							{pollData?.total_user_votes}
																						</span>{' '}
																						Votes
																						{/* {pollData?.expiration_time && (
																		{moment(pollData?.expiration_time).fromNow()}
																	)} */}
																					</div>
																				</div>
																			))}
																	</>
																)}
																<div className="card-body p-0 mt-12">
																	<span
																		className="cursor-pointer p-8 pl-0 inline-block align-middle"
																		aria-hidden="true"
																		onClick={e =>
																			handlePostVote(
																				false,
																				data.is_user_up_vote,
																				data.id
																			)
																		}
																	>
																		{data.is_user_up_vote ? (
																			<img
																				className="align-middle w-20 h-20"
																				src="assets/images/icon/like.png"
																				alt="Post_img"
																			/>
																		) : (
																			<img
																				className="align-middle w-20 h-20"
																				src="assets/images/icon/no_like.png"
																				alt="Post_img"
																			/>
																		)}
																		{/* {data?.response?.up_vote} Like */}
																	</span>
																	{/* <Typography
													className="text-sm text-secondary mr-20 mb-12 sm:mb-0 flex cursor-pointer"
													onClick={e => {
														!data.response.is_user_down_vote
															? handlePostVote(true, false, data.response.id)
															: '';
													}}
												>
													{data.response.is_user_down_vote === true ? (
														<img
															className="align-middle mr-5 w-20 h-20"
															src="assets/images/icon/thumb_down.png"
															alt="Post_img"
														/>
													) : (
														<span
															className="material-icons align-middle mr-5 text-red-600 text-20"
															aria-hidden="true"
														>
															thumb_down
														</span>
													)}
													{data?.response?.down_vote} DisLike
												</Typography> */}
																	<span
																		className="inline-block p-8 inline-block align-middle cursor-pointer"
																		onClick={() => {
																			dispatch(openCommentDialog());
																			setPostId(data?.id);
																		}}
																		aria-hidden="true"
																	>
																		<img
																			className="align-middle w-20 h-20 inline-block"
																			src="assets/images/icon/chat.png"
																			alt="comment"
																		/>
																		{/* {data?.comments} Comment */}
																	</span>
																	<span className="p-8 inline-block align-middle">
																		<img
																			className="align-middle w-20 h-20 inline-block cursor-pointer"
																			src="assets/images/icon/send.png"
																			alt="share"
																			onClick={() => {
																				setOpenShareDialog(true);
																				setPostUrl(data?.shared_url);
																			}}
																			aria-hidden="true"
																		/>
																	</span>
																	<span
																		className="cursor-pointer p-8 pl-0 float-right inline-block align-middle"
																		aria-hidden="true"
																		onClick={e =>
																			handleBookmark(data.is_bookmark, data.id)
																		}
																	>
																		{data.is_bookmark ? (
																			<img
																				className="align-middle w-20 h-20"
																				src="assets/images/icon/bookmark_save.png"
																				alt="bookmark_img"
																			/>
																		) : (
																			<img
																				className="align-middle w-20 h-20"
																				src="assets/images/icon/bookmark.png"
																				alt="bookmark_img"
																			/>
																		)}
																	</span>
																</div>
																<div>
																	{data?.up_vote > 0 && (
																		<span
																			className="cursor-pointer text-black font-semibold block mb-8"
																			onClick={() => {
																				dispatch(openLikesListDialog());
																				setPostId(data?.id);
																			}}
																			aria-hidden="true"
																		>
																			{data?.up_vote} Likes
																		</span>
																	)}
																	{data?.comments > 0 && (
																		<span
																			className="cursor-pointer text-grey block mb-8"
																			aria-hidden="true"
																			onClick={() => {
																				dispatch(openCommentDialog());
																				setPostId(data?.id);
																			}}
																		>
																			View all {data?.comments} Comments
																		</span>
																	)}
																	<span className="text-grey-500 block text-12">
																		{moment(data?.date).fromNow()}
																	</span>
																</div>
															</div>
														</div>
													))}
											</>
										)}
									</div>
								))}
						</div>
					</div>
					<Dialog
						open={openDialog}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenDialog(false);
							}
						}}
					>
						<DialogTitle id="alert-dialog-title">Watchlist</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{iswatched === false
									? 'Are you sure you want to Add this Stock to your Watchlist?'
									: 'Are you sure you want to remove this Stock from Watchlist?'}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									setOpenDialog(false);
								}}
								color="primary"
							>
								No
							</Button>
							<Button onClick={handleWatchlist} color="primary" autoFocus>
								Yes
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={
							openDialogWatchAll ||
							openDialogFollowAll ||
							openDialogFollow ||
							openDialogFollowTopPer ||
							openDialogPerformersFollowAll
						}
						onClose={(event, reason) => {
							if (reason !== 'backdropClick') {
								setOpenDialogWatchAll(false);
								setOpenDialogFollowAll(false);
								setOpenDialogFollow(false);
								setOpenDialogFollowTopPer(false);
								setOpenDialogPerformersFollowAll(false);
							}
						}}
						maxWidth="xs"
					>
						{/* <DialogTitle id="alert-dialog-title">{openDialogWatchAll ? 'Watchlist' : 'Follow'}</DialogTitle> */}
						<DialogContent>
							<DialogContentText
								id="alert-dialog-description"
								className="text-15 text-black text-center"
								style={{ maxWidth: '300px' }}
							>
								{(() => {
									if (openDialogWatchAll) {
										return 'Would you like to Add All Stock to your Watchlist?';
									}
									if (openDialogFollowAll) {
										return 'Would you like to follow all Top Influencers?';
									}
									if (openDialogPerformersFollowAll) {
										return 'Would you like to follow all Top Performers?';
									}
									return (
										<>
											<span className="block">Are you sure you want to Unfollow</span>
											<span className="block">
												<strong className="text-indigo-500">@{followUserName}</strong> ?
											</span>
										</>
									);
								})()}
							</DialogContentText>
						</DialogContent>
						<DialogActions className="text-center flex pb-20 px-20">
							<Button
								onClick={() => {
									if (openDialogWatchAll) {
										setOpenDialogWatchAll(false);
									} else if (openDialogFollowAll) {
										setOpenDialogFollowAll(false);
									} else if (openDialogFollowTopPer) {
										setOpenDialogFollowTopPer(false);
									} else if (openDialogPerformersFollowAll) {
										setOpenDialogPerformersFollowAll(false);
									} else {
										setOpenDialogFollow(false);
									}
								}}
								color="primary"
								className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
								style={{ border: '1px solid indigo' }}
							>
								No
							</Button>
							<Button
								onClick={() => {
									if (openDialogWatchAll) {
										handleWatchlistAll();
									} else if (openDialogFollowAll) {
										handleFollowAll();
									} else if (openDialogPerformersFollowAll) {
										handleFollowAll();
									} else if (openDialogFollowTopPer) {
										handleFollowUnfollow(followUserStatus, followUserId);
									} else {
										handleFollowUnfollowInfluencer(followUserStatus, followUserId);
									}
								}}
								color="primary"
								className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
								autoFocus
							>
								Yes
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						classes={{
							paper: 'm-24'
						}}
						open={openShareDialog}
						disableBackdropClick
						style={{ margin: '0 auto' }}
						onClose={() => {
							setOpenShareDialog(false);
						}}
						fullWidth
						maxWidth="xs"
					>
						<div className="flex justify-between p-12">
							<span className="text-16 font-semibold"> Share</span>
							<span
								onClick={() => {
									setOpenShareDialog(false);
								}}
								aria-hidden="true"
								className="text-right block cursor-pointer"
							>
								<Icon>close</Icon>
							</span>
						</div>
						<DialogContent classes={{ root: 'p-0 pt-0' }} style={{ overflowY: 'auto', paddingTop: '0' }}>
							<div className="items-center ng-star-inserted social-share">
								<ShareSocial
									url={postUrl}
									socialTypes={[
										'whatsapp',
										'facebook',
										'twitter',
										'email',
										'pinterest',
										'linkedin',
										'telegram',
										'reddit',
										'line',
										'instapaper'
									]}
								/>
							</div>
						</DialogContent>
					</Dialog>

					{influencerDialog.props.open ? <InfluencerDialog /> : ''}
					{marketMoversDialog.props.open ? <MarketMoversDialog /> : ''}
					{likesListDialog?.props?.open ? <LikesListDialog id={postId} /> : ''}
					{commentDialog?.props?.open ? <CommentDialog id={postId} /> : ''}
					{reportListDialog?.props?.open ? <ReportListDialog id={postId} /> : ''}
					{performersDialog.props.open ? <PerformersInfoDialog /> : ''}
				</div>
			}
		/>
	);
}

export default withReducer('applicationDashboardReducer', reducer)(DashboardPage);
