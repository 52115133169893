import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
import Toolbar from '@material-ui/core/Toolbar';
import { motion } from 'framer-motion';
import Typography from '@material-ui/core/Typography';
import { useRef, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import userService from 'app/services/userService';
import Query from 'app/main/query/user';
import Icon from '@material-ui/core/Icon';
import Avatar from 'react-avatar';
import history from '@history';
import _ from '@lodash';
import {
	closeinfluencerDialog,
	getInfluencerList,
	resetDashboard,
	userFollowUnfollow,
	getStockDashboard
} from './store/applicationDashboardSlice';

function InfluencerDialog() {
	const dispatch = useDispatch();

	const influencerDetails = useSelector(({ applicationDashboardReducer }) =>
		applicationDashboardReducer?.applicationDashboard?.getInfluencerListData?.data?.getInfluencerList?.data[0]
			?.response?.length > 0
			? applicationDashboardReducer.applicationDashboard.getInfluencerListData.data.getInfluencerList.data[0]
					.response
			: []
	);

	const appDialog = useSelector(
		({ applicationDashboardReducer }) => applicationDashboardReducer?.applicationDashboard?.influencerDialog
	);

	const listInnerRef = useRef();
	const [loading, setLoading] = useState(true);
	const loginUserData = userService.getUserData();
	const [currPage, setCurrPage] = useState(1); // storing current page number
	const [influencerList, setinfluencerList] = useState(influencerDetails);
	const [isFetching, setIsFetching] = useState(false);
	const userData = userService.getUserData();
	const [isNoneData, setIsNoneData] = useState(true);
	const [followLoading, setFollowLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [followUserName, setFollowUserName] = useState(false);
	const [followUserId, setFollowUserId] = useState([]);
	const [followUserStatus, setFollowUserStatus] = useState(false);

	useEffect(() => {
		const unmounted = false;
		if (currPage) {
			setIsFetching(true);
			const payload = {
				query: Query.getInfluencerList,
				variables: { pageNumber: currPage, pageSize: 10 }
			};

			dispatch(getInfluencerList(payload));

			if (!unmounted) {
				setLoading(false);
			}
		}
		return () => {
			dispatch(resetDashboard());
		};
	}, [dispatch, currPage]);

	useEffect(() => {
		if (influencerDetails && influencerDetails.length) {
			setinfluencerList([...influencerList, ...influencerDetails]);
			setIsFetching(false);
		} else {
			setTimeout(() => {
				setIsFetching(false);
			}, 1000);
			setIsNoneData(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [influencerDetails]);

	const onScroll = () => {
		setIsFetching(true);
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight) {
				setCurrPage(currPage + 1);
			}
			setIsFetching(false);
		}
	};

	function closeComposeDialog() {
		dispatch(closeinfluencerDialog());
	}

	function handleFollowUnfollow(followStatus, userIds) {
		setFollowLoading(userIds);
		const userid = parseInt(userIds, 10);
		const payloadData = {
			query: Query.userFollowUnfollow,
			variables: { isFollow: !followStatus, userId: [userid] }
		};
		dispatch(userFollowUnfollow(payloadData));

		influencerList.map((element, i) => {
			if (element.user_id === userIds) {
				const newData = { ...JSON.parse(JSON.stringify(element)) };
				newData.is_follow = !followStatus;
				influencerList[i] = newData;
			}
			return true;
		});
		setinfluencerList(influencerList);
		setOpenDialog(false);
		const payload = {
			query: Query.getStockDashboard
		};
		setTimeout(() => {
			dispatch(getStockDashboard(payload));
		}, 800);
	}

	function redirectProfileRoutes(id) {
		return history.push({
			pathname: `../user/${id}`
		});
	}

	return (
		<>
			<Dialog
				classes={{
					paper: 'm-24'
				}}
				{...appDialog.props}
				disableBackdropClick
				onClose={closeComposeDialog}
				fullWidth
				maxWidth="xs"
			>
				<AppBar position="static" elevation={0}>
					<Toolbar className="flex w-full justify-between">
						<Typography variant="subtitle1" color="inherit">
							Top Influencers
						</Typography>
						<Button
							className="float-right bg-transparent rounded p-0 w-auto min-w-0"
							variant="contained"
							color="primary"
							type="button"
							onClick={() => {
								closeComposeDialog();
							}}
						>
							<Icon>close</Icon>
						</Button>
					</Toolbar>
				</AppBar>

				<DialogContent
					classes={{ root: 'p-16' }}
					className="overflow-x-hidden"
					onScroll={onScroll}
					ref={listInnerRef}
					style={{ height: '50vh', overflowY: 'auto' }}
				>
					{influencerList && influencerList?.length > 0 && (
						<>
							{influencerList.map((influencer, index) => (
								<motion.div
									className="widget w-full bg-white rounded-lg p-8 lg:p-6 bg-grey-100 mb-16"
									key={index}
								>
									<div className="flex items-center justify-between mb-10">
										<div className="flex items-center">
											<Typography
												className="w-52 block h-52 mx-auto rounded-full"
												onClick={() => redirectProfileRoutes(influencer.user_id)}
												component="span"
											>
												<Avatar
													name={
														influencer?.full_name
															? influencer?.full_name
															: influencer?.user_name
													}
													className="cursor-pointer mx-auto capitalize"
													size="52px"
													alt="Apps"
													round
													src={influencer?.user_img}
													maxInitials={2}
												/>
											</Typography>

											<Typography
												variant="subtitle1"
												className="ml-12 cursor-pointer"
												onClick={() => redirectProfileRoutes(influencer.user_id)}
												component="span"
											>
												<span
													title={`${influencer?.full_name}`}
													className="block capitalize MuiTypography-root-190 font-semibold text-12 text-black md:tracking-wide MuiTypography-subtitle1-201 whitespace-nowrap overflow-hidden text-ellipsis"
												>
													{influencer?.full_name ? influencer.full_name : ''}
												</span>
												<span className="block MuiTypography-root-190 text-12 text-grey md:tracking-wide MuiTypography-subtitle1-201">
													{influencer?.user_name ? `@${influencer?.user_name}` : ''}
												</span>
											</Typography>
										</div>
										{parseInt(influencer.user_id, 10) !== parseInt(loginUserData.id, 10) && (
											<button
												className={`whitespace-nowrap rounded border-1 border-indigo-500 py-8 text-center  font-semibold w-96 ${
													influencer?.is_follow
														? 'bg-white-500 text-indigo'
														: 'bg-indigo-500 text-white'
												}`}
												color="primary"
												type="submit"
												onClick={
													influencer.is_follow
														? () => {
																setOpenDialog(true);
																setFollowUserId(influencer.user_id);
																setFollowUserStatus(influencer.is_follow);
																setFollowUserName(influencer?.user_name);
														  }
														: () => {
																handleFollowUnfollow(
																	influencer.is_follow,
																	influencer.user_id
																);
														  }
												}
												aria-label="Following"
											>
												{influencer?.is_follow ? 'Following' : 'Follow'}
											</button>
										)}
									</div>
									<div className="flex justify-between">
										<div className="relative text-center">
											<span className="block MuiTypography-root-190 text-indigo-500 font-semibold lg:text-16 text-14 md:tracking-wide MuiTypography-subtitle1-201">
												{influencer.num_followers ? influencer.num_followers : '0'}
											</span>
											<span className="block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
												Followers
											</span>
										</div>
										<div className="relative text-center">
											<span className="block MuiTypography-root-190 text-indigo-500 font-semibold lg:text-16 text-14 md:tracking-wide MuiTypography-subtitle1-201">
												{influencer.num_posts ? influencer.num_posts : '0'}
											</span>
											<span className="block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
												Posts
											</span>
										</div>
										<div className="relative text-center">
											<span className="block MuiTypography-root-190 text-indigo-500 font-semibold lg:text-16 text-14 md:tracking-wide MuiTypography-subtitle1-201">
												{influencer.s_score ? influencer.s_score : '0'}
											</span>
											<span className="block MuiTypography-root-190 font-semibold text-sm text-grey md:tracking-wide MuiTypography-subtitle1-201">
												Stockly Score
											</span>
										</div>
									</div>
								</motion.div>
							))}
						</>
					)}
					{isNoneData && influencerList && influencerList.length === 0 && (
						<>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { delay: 0.1 } }}
								className="flex flex-1 items-center justify-center h-full"
							>
								<Typography color="textSecondary" variant="h5" className="text-16 pt-5">
									There are no any Top Loser!
								</Typography>
							</motion.div>
						</>
					)}
					{isFetching && (
						<div className="loader mt-20 ml-6 block text-center mx-auto">
							<CircularProgress className="mt-30 ml-30" size={30} disableShrink />
						</div>
					)}
				</DialogContent>
			</Dialog>
			{openDialog && (
				<Dialog
					open={openDialog}
					onClose={(event, reason) => {
						if (reason !== 'backdropClick') {
							setOpenDialog(false);
						}
					}}
					aria-labelledby="followers"
					aria-describedby="followers-description"
				>
					{/* <DialogTitle id="alert-dialog-title">Follow</DialogTitle> */}
					<DialogContent>
						<DialogContentText id="alert-dialog-description" className="text-15 text-black text-center">
							Are you sure you want to Unfollow
							<span className="block">
								<strong className="text-indigo-500">@{followUserName}</strong> ?
							</span>
						</DialogContentText>
					</DialogContent>
					<DialogActions className="text-center flex pb-20 px-20">
						<Button
							onClick={() => {
								setOpenDialog(false);
							}}
							className="rounded py-8 text-center font-semibold w-96 bg-white-500 text-indigo hover:bg-indigo-600 hover:text-white border border-indigo-500 w-1/2"
							style={{ border: '1px solid indigo' }}
							color="primary"
						>
							No
						</Button>
						<Button
							onClick={() => {
								handleFollowUnfollow(followUserStatus, followUserId);
							}}
							className="rounded border-1 border-indigo-500 py-8 text-center font-semibold w-96 bg-indigo-500 text-white hover:bg-indigo-600 w-1/2"
							color="primary"
							autoFocus
						>
							Unfollow
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}

export default InfluencerDialog;
